import Report from './Report'

export const ReportAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/reports/factoryreport",
            component: Report
        }
    ]
};


