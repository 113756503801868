import React from "react";
export const StockValuationAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/reports/closingstockvaluation",
      component: React.lazy(() => import("./StockValuation")),
    },
  ],
};
