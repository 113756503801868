class stockNavConfig {

  static stockNavConfigArr () {
    const roleOfUser = localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : null;
    const module = roleOfUser ? roleOfUser['Stock'] : null
    const keys = module ? Object.keys(module) : []
    const isLocation = localStorage.getItem('isLocation') ? JSON.parse(localStorage.getItem('isLocation')) : "";
  
      const dataArr = [
      {
        id: "stocklist",
        title: "Stock List",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/stock/:stock",
        isAccessible: true
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
          id: "transferstock",
          title: "Stock Transfer",
          type: "item",
          icon: "play_circle_outline",
          url: "/dashboard/stock/:stock?transferstocks",
          isAccessible : keys.includes('Stock Transfer') && isLocation === 0
        },
        {
          type: "divider",
          id: "divider-2",
        },
        {
          id: "accepttransferstock",
          title: "Accept Stock Transfer",
          type: "item",
          url: "/dashboard/stock/accepttransferstock/:stock",
          icon: "play_circle_outline",
          isAccessible : keys.includes('Accept Stock Transfer') && isLocation === 0
        },
        {
          type: "divider",
          id: "divider-3",
        },
      ];
  return dataArr
  }
}

  export default stockNavConfig;
  