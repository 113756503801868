import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import NavbarSetting from "app/main/NavbarSetting/NavbarSetting";

const SalesBlankPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    NavbarSetting('Sales Purchase', dispatch)
    //eslint-disable-next-line
  }, []);

  return (
    <div 
        style={{
            alignItems:"center",
            height:"calc(100vh - 200px)",
            width:"100vw",
            display:"flex",
            justifyContent:"center",
         }}
    >

    <div
         className="flex justify-center align-middle text-center"
         style={{
            height: "calc(100vh - 460px)",
            background: "#f1f1f1",
            display: "flex",
            width:"80%",
            minHeight:"500px",
          }}
       >
      <h3
         style={{
           fontSize: 50,
           fontWeight: 900,
           color: "#c2c2c2",
           alignItems:"center",
           display:"flex",
         }}
      >
         Please Select Sales/Purchase Type
       </h3>
     </div>

    </div>
   );
};

export default SalesBlankPage;
