import React, { useContext, useState, useEffect } from "react";
import { AppBar, Hidden, Toolbar } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { FuseSearch } from "@fuse"; //, FuseShortcuts
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import jwtService from "app/services/jwtService";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import Notification from "app/fuse-layouts/shared-components/Notification";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Config from "app/fuse-configs/Config";
import * as Actions from "app/store/actions";
import Select, { createFilter } from "react-select";
import AppContext from "app/AppContext";
import handleError from "app/main/ErrorComponent/ErrorComponent";

const useStyles = makeStyles((theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
}));

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const theme = useTheme();

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };

  const [departmentData, setDepartmentData] = useState([]);
  const { selectedDepartment, setSelectedDepartment } = useContext(AppContext);
  const [master, setMaster] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  function handleDepartmentChange(value) {
    let tempVal = Number(value.value.split("-")[1]);
    setSelectedDepartment(value);
    localStorage.setItem("SelectedDepartment", tempVal);
    localStorage.setItem("selDeptNm", value.label);
    localStorage.setItem("isLocation", value.is_location)

    const pathname = window.location.pathname
    // const reloadPage = window.location.reload();
    if (pathname === '/dashboard/stock/:stock' || pathname === '/dashboard/stock') {
      return window.location.reload();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      let authToken = jwtService.getAccessToken();
      if (authToken !== null && jwtService.isAuthTokenValid(authToken))
        getDepartmentData(); //user department
    }, 1000);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
    };
  }, []);

  useEffect(() => {
    checkRoute();
    checkForSearch();
    //eslint-disable-next-line
  }, [window.location.pathname]);

  const checkRoute = () => {
    const currPath = window.location.pathname;
    const pathArr = currPath.split("/");
    if (
      `${pathArr[1]}/${pathArr[2]}` === "dashboard/masters" ||
      `${pathArr[1]}/${pathArr[2]}` === "dashboard/mobappadmin"
      // `${pathArr[1]}/${pathArr[2]}` === "dashboard/production"
    ) {
      setMaster(true);
    } else {
      setMaster(false);
    }
  };

  const checkForSearch = () => {
    const currPath = window.location.pathname;
    if (currPath === "/dashboard" || currPath === "/dashboard/design") {
      setIsSearched(false);
    } else {
      setIsSearched(true);
    }
  };

  function getDepartmentData() {
    axios
      .get(Config.getCommonUrl() + "api/admin/getdepartmentlist")
      .then(function (response) {
        if (response.data.success === true) {

          let data = response.data.data;
          // let data = response.data.data.filter((s) => s.is_location !== 1);
          setDepartmentData(data);
          localStorage.setItem("allDepartments", JSON.stringify(data));
          const findIndex = data.findIndex((a) => a.is_main === 1);
          const prevDept = localStorage.getItem("SelectedDepartment");
          const prevLoc = localStorage.getItem("isLocation");
          if (prevDept) {
            data.map((item, index) => {
              if (Number(prevDept) === item.id && Number(prevLoc) === item.is_location) {
                setSelectedDepartment({
                  value: `SL${item.is_location}-${item.id}`, // item.id,
                  label: item.name,
                  is_location: item.is_location
                });
                localStorage.setItem("selDeptNm", item.name);
                localStorage.setItem("isLocation", item.is_location)
              }
              return null;
            });
            localStorage.setItem("SelectedDepartment", prevDept);
          } else {
            if (findIndex > -1) {
              setSelectedDepartment({
                value: `SL${data[findIndex].is_location}-${data[findIndex].id}`, //data[findIndex].id,
                label: data[findIndex].name,
                is_location: data[findIndex].is_location
              });
              localStorage.setItem("SelectedDepartment", data[findIndex].id);
              localStorage.setItem("selDeptNm", data[findIndex].name);
              localStorage.setItem("isLocation", data[findIndex].is_location);
            }
          }
        } else {
          setDepartmentData([]);
          dispatch(Actions.showMessage({ message: response.data.message }));
        }
      })
      .catch((error) => {
        handleError(error, dispatch, { api: "api/admin/getdepartmentlist" });
      });
  }

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className="flex relative z-10 navbar-dv"
        style={{ background: "#141A21", color: "#ffffff" }}
      >
        <Toolbar className="p-0">
          {config.navbar.display && config.navbar.position === "left" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton className="w-64 h-64 p-0" />
              <div className={classes.separator} />
            </Hidden>
          )}

          <div className="flex flex-1 menu-left-vkj">
            <ConditionalLink
              to="/dashboard"
              condition={localStorage.getItem("isDesigner") == "true"}
            >
              <img
                className="vkj-navbar-logo "
                style={{ alignSelf: "start", cursor: "pointer" }}
                // src={
                //   Config.getS3Url() +
                //   "images/avatars/vk-jewels-pvt-ltd-logo.svg"
                // }
                src={Config.getvkjLogo()}
                alt="logo"
              />
            </ConditionalLink>
            {/* <Link to="/dashboard" tabIndex="-1">
              <img
                className="vkj-navbar-logo "
                style={{ alignSelf: "start", cursor: "pointer" }}
                src={
                  Config.getS3Url() +
                  "images/avatars/vk-jewels-pvt-ltd-logo.svg"
                }
                alt="logo"
              //   onClick={handleLogoClick}
              />
            </Link> */}
          </div>

          <div className="flex right-menu-dv">
            <div className="inner-right-navbar-dv">
              <UserMenu />

              <div className={classes.separator} />
              <Notification />

              {localStorage.getItem("isDesigner") !== "true" && isSearched ? (
                <>
                  <div className={classes.separator} />
                  <FuseSearch />
                </>
              ) : null}

              <div className={classes.separator} />
              {/* {!master ? (
                <Select
                  id="nav-dropdown-dv"
                  tabIndex="1"
                  filterOption={createFilter({ ignoreAccents: false })}
                  classes={classes}
                  styles={selectStyles}
                  style={{ alignSelf: "center" }}
                  // options={departmentData.map((suggestion) => ({
                  //   value: suggestion.id,
                  //   label: suggestion.name,
                  //   isDisabled : suggestion.is_location === 1 ? true : false
                  // }))}
                  options={departmentData.map((suggestion, i) => ({
                    value: `SL${suggestion.is_location}-${suggestion.id}`,
                    label: suggestion.name,
                    is_location: suggestion.is_location
                    // isDisabled: suggestion.is_location === 1 ? true : false,
                  }))}
                  // components={components}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  placeholder="Select Location/ Dept"
                />
              ) : null} */}

              {/* <Hidden lgUp> chat panel and quick panel button right above

                            <div className={classes.separator}/>

                            <ChatPanelToggleButton/>
                        </Hidden>

                        <div className={classes.separator}/>

                        <QuickPanelToggleButton/> */}
            </div>
          </div>

          {/* {config.navbar.display && config.navbar.position === "right" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton />
            </Hidden>
          )} */}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

//if user is designer then he can not redirect to dashboard by clicking logo
function ConditionalLink({ children, condition, ...props }) {
  return !condition && props.to ? (
    <Link {...props}>{children}</Link>
  ) : (
    <>{children}</>
  );
}

export default ToolbarLayout1;
