import React from 'react';

export const AddNewsUpdatesAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/mobappadmin/newsupdate/addnewsupdate",
            component: React.lazy(() => import('./AddNewsUpdates'))
        }
    ]
};


