import React from "react";
export const OrderStatusReportAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/reports/orderstatusreport",
      component: React.lazy(() => import("./OrderStatusReport")),
    },
  ],
};
