import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from 'app/auth/store/reducers';
// import { reducer as reduxFormReducer } from 'redux-form';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        // form: reduxFormReducer,
        ...asyncReducers
    });

export default createReducer;
