import React from 'react';

export const BirthdayListAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/mobappadmin/usermaster/birthdaylist",
            component: React.lazy(() => import('./BirthdayList'))
        }
    ]
};


