class Config {
  static getCommonUrl() {
    // return "http://localhost:4000/";
    return 'https://api.gemsight.wpzone.fun/';
    // return "https://api-vkj.developmentlabs.co/";
    // return "https://api-uatvkj.developmentlabs.co/";
    //test
    //test1
  }
  static getS3Url() {
    //return "https://vkjdev.s3.ap-south-1.amazonaws.com/"
    return process.env.REACT_APP_S3_URL;
  }
  static getSiteUrl() {
    //return "http://localhost:4000/";
    //return "https://vkj.developmentlabs.co/"
    return process.env.REACT_APP_SITE_URL;
    //return "https://erp.vkjewels.net/"
  }
  static getCatalogUrl() {
    // return "https://catalogue.developmentlabs.co/"
    return process.env.REACT_APP_CATALOGUE_URL;
    //return "https://catalogue.vkjewels.net/"
  }
  static getvkjLogo() {
    const siteSetData = localStorage.getItem("siteSetting")
      ? JSON.parse(localStorage.getItem("siteSetting"))
      : [];
    return `https://ci-erp.s3.ap-south-1.amazonaws.com/design/image/logo-2.png`;
  }
  static numWithComma(num) {
    // console.log("num", num);
    if (isNaN(num) || num === "") {
      return "";
    }
    const n1 = parseFloat(num);
    return n1.toLocaleString("en-IN", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }
  static numWithCommaTwo(num) {
    // console.log("num", num);
    if (isNaN(num) || num === "") {
      return "";
    }
    const n1 = parseFloat(num);
    return n1.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  static numWithCommaSix(num) {
    // console.log("num", num);
    if (isNaN(num) || num === "") {
      return "";
    }
    const n1 = parseFloat(num);
    return n1.toLocaleString("en-IN", {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6,
    });
  }
  static numWithoutDecimal(num) {
    if (isNaN(num) || num === "") {
      return "";
    }
    const n1 = parseFloat(num);
    return n1.toLocaleString("en-IN");
  }
  static idDesigner() {
    return localStorage.getItem("isDesigner") == "true";
  }
  static checkFile(arr, type) {
    let result = true;
    for (let i = 0; i < arr.length; i++) {
      if (type == "image") {
        let type1 = arr[i].type.split("/");
        if (type1[0] != type) {
          return false;
        }
      } else {
        if (arr[i].type != type) {
          result = false;
        }
      }
    }
    return result;
  }
  static getDesignationList() {
    const roles = [
      "Director",
      "Managing Director",
      "CEO",
      "General Manager",
      "Proprietor",
      "Partner",
      "CFO",
      "Purchase Manager",
      "Purchase Head",
      "Salesman",
      "Other",
    ];
    return roles;
  }
}

export default Config;
