import React from 'react';

export const AddSalesmanMobAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/mobappadmin/salesman/addsalesman",
            component: React.lazy(() => import('./AddSalesmanMob'))
        }
    ]
};


