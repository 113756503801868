import React from 'react';
export const AddSalesJobworkAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/dashboard/sales/salejobwork/addsalejobwork',
            component: React.lazy(() => import('./AddSalesJobwork'))
        }
    ]
};
