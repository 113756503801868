import React, { useContext, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { FuseAnimate } from "@fuse";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, Icon, IconButton } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppContext from "app/AppContext";
import axios from "axios";
import Config from "app/fuse-configs/Config";
import { useDispatch } from "react-redux";
import * as Actions from "app/store/actions";
import Loader from "app/main/Loader/Loader";
import { CSVLink } from "react-csv";
import Select, { createFilter } from "react-select";
import moment from "moment";
import Button from "@material-ui/core/Button";
import ReportListView from "./Subviews/ReportListView";
import NavbarSetting from "app/main/NavbarSetting/NavbarSetting";
import handleError from "app/main/ErrorComponent/ErrorComponent";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabIndicator: {
    backgroundColor: "black",
  },
  button: {
    textTransform: "none",
    color: "white",
    borderRadius: "8px !important",
    background: "#1C252E",
    "&:hover": {
      backgroundColor: "#1C252E",
    },
  },
  table: {
    minWidth: 650,
  },
}));

const Report = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [modalView, setModalView] = useState(0);

  const [allData, setAllData] = useState([]);
  const [goldLooseList, setGoldLooseList] = useState([]);
  const [otherLooseList, setOtherLooseList] = useState([]);
  const [packingSLipData, setPackingSlipData] = useState([]);
  const [packetData, setPacketData] = useState([]);
  const [lotData, setLotData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [selectedDeptErr, setSelectedDeptErr] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedDateErr, setSelectedDateErr] = useState("");

  const appContext = useContext(AppContext);
  const { selectedDepartment } = appContext;

  const [loading, setLoading] = useState(false);

  const roleOfUser = localStorage.getItem("permission")
    ? JSON.parse(localStorage.getItem("permission"))
    : null;
  const [authAccessArr, setAuthAccessArr] = useState([]);

  useEffect(() => {
    let arr = roleOfUser
      ? roleOfUser["Report"]["Factory Stock"]
        ? roleOfUser["Report"]["Factory Stock"]
        : []
      : [];
    const arrData = [];
    if (arr.length > 0) {
      arr.map((item) => {
        arrData.push(item.name);
      });
    }
    setAuthAccessArr(arrData);
  }, []);

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };
  useEffect(() => {
    NavbarSetting("Report", dispatch);
  }, []);

  useEffect(() => {
    getdepartmentlist();
    // getStockData(`api/stock?end=${selectedDate}`);
  }, [dispatch]);

  // useEffect(() => {
  //   if (loading) {
  //     setTimeout(() => setLoading(false), 3000);
  //   }
  // }, [loading]);

  const handleChangeTab = (event, value) => {
    setModalView(value);
  };

  function getStockData(url) {
    setLoading(true);
    axios
      .get(Config.getCommonUrl() + url)
      .then(function (response) {
        console.log(response);
        if (response.data.success === true) {
          // console.log(response.data.data);
          const tmpAllData = response.data.data;
          let tempData = tmpAllData.map((item) => {
            return {
              ...item,
              type:
                item.stockType === "Barcode"
                  ? 1
                  : item.stockType === "Packet"
                    ? 2
                    : item.stockType === "Packing Slip"
                      ? 3
                      : item.stockType === "Lot"
                        ? 4
                        : null,
              fineGold:
                item.flag === 1 && item.item_id === 1
                  ? parseFloat(
                    (parseFloat(item.net_weight) * parseFloat(item.purity)) /
                    100
                  ).toFixed(3)
                  : "-",
              pcs: item.flag === 1 && item.item_id === 1 ? "-" : item.pcs,
            };
          });
          let allArr = [];
          let goldLooseArr = [];
          let otherLooseArr = [];
          let packingSlipArr = [];
          let packetArr = [];
          let productArr = [];
          let lotData = [];
          // const stockFlag = {
          //   goldStockCode: 1,
          //   looseStockCode: 2,
          //   lot: 3,
          //   barCode: 4,
          //   packet: 5,
          //   packingSlip: 6,
          // };

          for (let item of tempData) {
            // console.log(item)
            allArr.push(item);

            if (item.flag === 1) {
              goldLooseArr.push(item);
            } else if (item.flag === 2) {
              otherLooseArr.push(item);
            } else if (item.flag === 3) {
              lotData.push(item);
            } else if (item.flag === 4) {
              productArr.push(item);
            } else if (item.flag === 5) {
              packetArr.push(item);
            } else if (item.flag === 6) {
              //packing slip data
              packingSlipArr.push(item);
            }
          }

          setAllData(allArr);
          setGoldLooseList(goldLooseArr);
          setOtherLooseList(otherLooseArr);
          setPackingSlipData(packingSlipArr);
          setPacketData(packetArr);
          setProductData(productArr);
          setLotData(lotData);

          setLoading(false);

          let tmpDlData = allArr.map((item) => {
            return {
              "Stock Type": item.stockType,
              "Stock Code": item.stock_name_code,
              Category: item.hasOwnProperty("category_name")
                ? item.category_name
                : "",
              Purity: item.purity,
              Pieces: item.pcs,
              "Gross Weight": item.gross_weight,
              "Net Weight": item.net_weight,
              "Fine Gold": item.fineGold,
              "Other Wight": item.other_weight,
              // below fields are not needed in csv
              // info: "",//item.element.
              // material_details: item.hasOwnProperty("material_detail") ? item.material_detail : "",
              // previous_process: item.process,
              // last_Performed_V_Num: item.voucher_no,
              // transit: ""
            };
          });

          setDownloadData(tmpDlData);
        } else {
          setLoading(false);
          setAllData([]);
          setGoldLooseList([]);
          setOtherLooseList([]);
          setPackingSlipData([]);
          setPacketData([]);
          setProductData([]);
          setLotData([]);
          dispatch(
            Actions.showMessage({
              message: response.data.message,
            })
          );
        }
      })
      .catch(function (error) {
        // console.log(error);
        setLoading(false);
        setAllData([]);
        setGoldLooseList([]);
        setOtherLooseList([]);
        setPackingSlipData([]);
        setPacketData([]);
        setProductData([]);
        setLotData([]);

        handleError(error, dispatch, { api: url });
      });
  }

  function getdepartmentlist() {
    axios
      .get(Config.getCommonUrl() + `api/department/common/all`)
      .then(function (response) {
        console.log(response);
        if (response.data.success === true) {
          console.log(response.data.data);
          setDeptList(response.data.data);
        } else {
          dispatch(
            Actions.showMessage({
              message: response.data.message,
            })
          );
        }
      })
      .catch(function (error) {
        handleError(error, dispatch, { api: `api/department/common/all` });
      });
  }

  const handleChangeDept = (value) => {
    setSelectedDept(value);
    setSelectedDeptErr("");
    setSelectedDateErr("");
  };

  function validateDept() {
    if (selectedDept === "") {
      setSelectedDeptErr("Please select department");
      return false;
    }
    return true;
  }
  console.log(selectedDate, "525636");
  function fromDtValidation() {
    const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    if (!selectedDate || dateRegex.test(selectedDate) === false) {
      setSelectedDateErr("Enter Valid Date!");
      return false;
    } else {
      setSelectedDateErr("");
      return true;
    }
  }

  const setFilter = () => {
    if (validateDept() && fromDtValidation()) {
      const filtreApi = `api/stock?department_id=${selectedDept.value}&end=${selectedDate}`;
      getStockData(filtreApi);
    }
  };

  const resetFuncCall = () => {
    if (selectedDept !== "") {
      setSelectedDept("");
      setSelectedDeptErr("");
      setSelectedDate(moment().format("YYYY-MM-DD"));
      setSelectedDateErr("");
      getStockData(`api/stock`);
    }
  };
  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var today = moment().format("YYYY-MM-DD");
    if (name === "fromDate") {
      setSelectedDate(value);
      let dateVal = moment(value).format("YYYY-MM-DD"); //new Date(value);
      let minDateVal = moment(new Date("01/01/1900")).format("YYYY-MM-DD");
      if (dateVal <= today && minDateVal < dateVal) {
        setSelectedDateErr("");
      } else {
        setSelectedDateErr("Enter Valid Date");
      }
    }
  }

  return (
    <div className={clsx(classes.root, props.className, "w-full")}>
      <FuseAnimate animation="transition.slideUpIn" delay={200}>
        <div className="flex flex-col md:flex-row container  table-width-mt">
          <div className="flex flex-1 flex-col min-w-0  ">
            <Grid
              className="packing-fullwidth-title"
              container
              spacing={4}
              alignItems="stretch"
              style={{ margin: 0 }}
            >
              <Grid item xs={12} sm={4} md={3} key="1" style={{ padding: 0 }}>
                <FuseAnimate delay={300}>
                  <Typography className="p-16 pb-8 text-18 font-700">
                    Factory Stock
                  </Typography>
                </FuseAnimate>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ paddingInline: "16px" }}
              >
                <Grid item xs={12} sm={6} md={3} key="2">
                  <Select
                    classes={classes}
                    styles={selectStyles}
                    options={deptList.map((dept) => ({
                      value: dept.id,
                      label: dept.name,
                    }))}
                    filterOption={createFilter({ ignoreAccents: false })}
                    value={selectedDept}
                    onChange={handleChangeDept}
                    placeholder="Select Department"
                  />
                  <span style={{ color: "red" }}>
                    {selectedDeptErr.length > 0 ? selectedDeptErr : ""}
                  </span>
                </Grid>

                <Grid item xs={12} sm={6} md={3} key="3">
                  <TextField
                    label="Date"
                    autoFocus
                    name="fromDate"
                    type="date"
                    // onKeyDown={(e => e.preventDefault())}
                    value={selectedDate}
                    error={selectedDateErr.length > 0 ? true : false}
                    helperText={selectedDateErr}
                    onChange={(e) => handleInputChange(e)}
                    variant="outlined"
                    required
                    fullWidth
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item style={{ display: "flex", gap: "10px" }}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={setFilter}
                  >
                    Filter
                  </Button>

                  <Button
                    variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={resetFuncCall}
                  >
                    Reset
                  </Button>
                </Grid>
                {authAccessArr.includes("Export Factory Stock") && (
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <IconButton
                      style={{
                        color: "#1C252E",
                        padding: "0",
                        height: "100%",
                        marginTop: "6px",
                      }}
                      className="ml-0"
                    >
                      <CSVLink
                        data={downloadData}
                        filename={
                          "Factory_" +
                          new Date().getDate() +
                          "_" +
                          (new Date().getMonth() + 1) +
                          "_" +
                          new Date().getFullYear() +
                          ".csv"
                        }
                      >
                        <Icon style={{ color: "dodgerblue", fontSize: "35px" }}>
                          get_app
                        </Icon>
                      </CSVLink>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {loading && <Loader />}

            <Grid
              className="department-tab-pt department-tab-blg-dv stocklist-tabel-blg"
              style={{ marginBottom: "8%" }}
            >
              <div className={classes.root}>
                <AppBar
                  style={{
                    background: "transparent",
                    color: "#1C252E",
                    marginBottom: "10px",
                  }}
                  position="static"
                >
                  <Tabs
                    TabIndicatorProps={{
                      className: classes.tabIndicator,
                    }}
                    value={modalView}
                    onChange={handleChangeTab}
                    scrollButtons="auto"
                  >
                    <Tab label="All List" />
                    <Tab label="Loose Metal" />
                    <Tab label="Other Material" />
                    <Tab label="Lot List" />
                    <Tab label="Barcode List" />
                    <Tab label="Packet List" />
                    <Tab label="Packing Slip List" />
                  </Tabs>
                </AppBar>
                {modalView === 0 && (
                  <ReportListView
                    props={props}
                    allData={allData}
                    date={selectedDate}
                  />
                )}
                {modalView === 1 && (
                  <ReportListView
                    props={props}
                    allData={goldLooseList}
                    date={selectedDate}
                  />
                )}
                {modalView === 2 && (
                  <ReportListView
                    props={props}
                    allData={otherLooseList}
                    date={selectedDate}
                  />
                )}
                {modalView === 3 && (
                  <ReportListView
                    props={props}
                    allData={lotData}
                    date={selectedDate}
                  />
                )}
                {modalView === 4 && (
                  <ReportListView
                    props={props}
                    allData={productData}
                    date={selectedDate}
                  />
                )}
                {modalView === 5 && (
                  <ReportListView
                    props={props}
                    allData={packetData}
                    date={selectedDate}
                  />
                )}
                {modalView === 6 && (
                  <ReportListView
                    props={props}
                    allData={packingSLipData}
                    date={selectedDate}
                  />
                )}
              </div>
            </Grid>
          </div>
        </div>
      </FuseAnimate>
    </div>
  );
};

export default Report;
