import React from 'react';

export const CreateCompanyAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/mobappadmin/retailermaster/createretailer",
            component: React.lazy(() => import('./CreateCompany'))
        },
        {
            path     : "/dashboard/mobappadmin/prospectiveorders/createretailer",
            component: React.lazy(() => import('./CreateCompany'))
        },
        {
            path     : "/dashboard/mobappadmin/orders/createretailer",
            component: React.lazy(() => import('./CreateCompany'))
        },
        {
            path     : "/dashboard/mobappadmin/usermaster/createretailer",
            component: React.lazy(() => import('./CreateCompany'))
        }
    ]
};


