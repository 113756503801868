import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "app/auth/store/actions";
import * as Actions from "app/store/actions";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "app/fuse-configs/Config";
import handleError from "app/main/ErrorComponent/ErrorComponent";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  // const [userMenu, setUserMenu] = useState(null);

  // const userMenuClick = (event) => {
  //   setUserMenu(event.currentTarget);
  // };

  // const userMenuClose = () => {
  //   setUserMenu(null);
  // };

  function callLogOutApi() {
    axios
      .post(Config.getCommonUrl() + "admin/logout")
      .then(function (response) {
        if (response.data.success === true) {
          handleClose();
          dispatch(
            Actions.showMessage({
              message: response.data.message,
              variant: "success",
            })
          );
          dispatch(authActions.logoutUser("Logout", { api: "admin/logout" }));
        } else {
          dispatch(
            Actions.showMessage({
              message: response.data.message,
              variant: "error",
            })
          );
        }
      })
      .catch((error) => {
        handleError(error, dispatch, { api: "admin/logout" });
      });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        className="h-64"
        // onClick={userMenuClick}
        onClick={handleClick}
        tabIndex="4"
        id="basic-button"
        aria-controls={open ? "basic-menu" : null}
        aria-haspopup="true"
        aria-expanded={open ? "true" : null}
        style={{ color: "#ffffff" }}
      >
        <Icon>account_circle</Icon>
        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {user.data.displayName}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   "aria-labelledby": "basic-button",
        // }}
        // // open={Boolean(userMenu)}
        // // anchorEl={userMenu}
        // // onClose={userMenuClose}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "center",
        // }}
        classes={{
          paper: "py-8",
        }}
        // id="simple-menu"
        // anchorEl={anchorEl}
        keepMounted={false}
        // open={Boolean(anchorEl)}
        // onClose={this.handleClose}
        PaperProps={{
          style: {
            transform: "translateX(10px) translateY(50px)",
          },
        }}
      >
        {/* <React.Fragment> */}
        <MenuItem component={Link} to="/pages/profile" onClick={handleClose}>
          <ListItemIcon className="min-w-40">
            <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText className="pl-0" primary="Change Password" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            callLogOutApi();
          }}
        >
          <ListItemIcon className="min-w-40">
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText className="pl-0" primary="Logout" />
        </MenuItem>
        {/* </React.Fragment> */}
      </Menu>
    </React.Fragment>
  );
}

export default UserMenu;
