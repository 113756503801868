import React from 'react';
import VKjLoader from './VKJLoader.gif';

const Loader = () => {
    return (
        <div
            className="loader-main-dv"
            style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            }}
        >
            <img className="spin-logo"
            src={VKjLoader}
            style={{
                alignSelf: "center",
                width: "100px",
                zIndex: "999",
                background: "#fff",
                borderRadius: "50%",
            }}
            alt="logo"
            />
        </div>
    )
}

export default Loader;