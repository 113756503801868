import React from "react";

export const ReceiveFromWorkerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/production/receivefromworker",
      component: React.lazy(() => import("./ReceiveFromWorker")),
    },
  ],
};
