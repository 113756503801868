class salesPurchaseNavConfig {
  static salesPurchaseNavConfigArr() {
    const roleOfUser = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null;
    const module = roleOfUser ? roleOfUser["Sales Purchase"] : null;
    const keys = module ? Object.keys(module) : [];

    const dataArr = [
      {
        id: "salesinfo",
        title: "Sales Info",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Sales Job Work Info") ||
          keys.includes("Sales Domestic Info") ||
          keys.includes("Sales B2C info") ||
          keys.includes("Sales Export Info"),
        children: [
          {
            id: "salesJobWorkinfo",
            title: "Sales Jobwork Info",
            type: "item",
            url: "/dashboard/sales/salejobworkinfo",
            isAccessible: keys.includes("Sales Job Work Info"),
          },
          {
            id: "salesDomesticinfo",
            title: "Sales Domestic Info",
            type: "item",
            url: "/dashboard/sales/domesticsaleinfo",
            isAccessible: keys.includes("Sales Domestic Info"),
          },
          {
            id: "salesb2cinfo",
            title: "Sales B2C Info",
            type: "item",
            url: "/dashboard/sales/b2csaleinfo",
            isAccessible: keys.includes("Sales B2C info"),
          },
          {
            id: "salesExportinfo",
            title: "Sales Export Info",
            type: "item",
            url: "/dashboard/sales/salesexportinfo",
            isAccessible: keys.includes("Sales Export Info"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "sales",
        title: "Sales",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Sales Job Work") ||
          keys.includes("Sales Domestic") ||
          keys.includes("Sales B2C") ||
          keys.includes("Sales Export") ||
          keys.includes("Sales Other"),
        children: [
          {
            id: "salesJobWork",
            title: "Sales Jobwork",
            type: "item",
            url: "/dashboard/sales/salejobwork",
            isAccessible: keys.includes("Sales Job Work"),
          },
          {
            id: "salesDomestic",
            title: "Sales Domestic",
            type: "item",
            url: "/dashboard/sales/domesticsale",
            isAccessible: keys.includes("Sales Domestic"),
          },
          {
            id: "slaesb2c",
            title: "Sales B2C",
            type: "item",
            url: "/dashboard/sales/salesb2c",
            isAccessible: keys.includes("Sales B2C"),
          },
          {
            id: "salesExport",
            title: "Sales Export",
            type: "item",
            url: "/dashboard/sales/salesexport",
            isAccessible: keys.includes("Sales Export"),
          },
          {
            id: "salesOther",
            title: "Sales Other",
            type: "item",
            url: "/dashboard/sales/salesother",
            isAccessible: keys.includes("Sales Other"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-2",
      },
      {
        id: "salesReturn",
        title: "Sales Return",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Sales Job Work Return") ||
          keys.includes("Sales Domestic Return"),
        children: [
          {
            id: "salesreturnJobWork",
            title: "Sales Jobwork Return",
            type: "item",
            url: "/dashboard/sales/salesreturnjobwork",
            isAccessible: keys.includes("Sales Job Work Return"),
          },
          {
            id: "salesreturnDomestic",
            title: "Sales Domestic Return",
            type: "item",
            url: "/dashboard/sales/salesreturndomestic",
            isAccessible: keys.includes("Sales Domestic Return"),
          },
          // {
          //   id: "salesreturnExport",
          //   title: "Sales Export Return",
          //   type: "item",
          //   url: "#",
          //   isAccessible : keys.includes('Issue to Hallmark'),
          // }
        ],
      },
      {
        type: "divider",
        id: "divider-3",
      },
      {
        id: "purchase",
        title: "Purchase",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Metal Purchase") ||
          keys.includes("Jewellery Purchase") ||
          keys.includes("Jewellery Purchase (Artician Receive)") ||
          keys.includes("Export Metal Purchase") ||
          keys.includes("Consumable Purchase") ||
          keys.includes("Raw Material Purchase"),
        children: [
          {
            id: "metal",
            title: "Metal Purchase",
            type: "item",
            url: "/dashboard/sales/metalpurchase",
            isAccessible: keys.includes("Metal Purchase"),
          },
          {
            id: "jewellery",
            title: "Jewellery Purchase",
            type: "item",
            url: "/dashboard/sales/jewellerypurchase",
            isAccessible: keys.includes("Jewellery Purchase"),
          },
          {
            id: "jewelleryArtician",
            title: "Jewellery (Artician Receive)",
            type: "item",
            url: "/dashboard/sales/articianjewellerypurchase",
            isAccessible: keys.includes(
              "Jewellery Purchase (Artician Receive)"
            ),
          },
          {
            id: "exportMetalPurchase",
            title: "Export Metal Purchase",
            type: "item",
            url: "/dashboard/sales/exportmetalpurchase",
            isAccessible: keys.includes("Export Metal Purchase"),
          },
          {
            id: "consumablePurchase",
            title: "Consumable Purchase",
            type: "item",
            url: "/dashboard/sales/consumablepurchase",
            isAccessible: keys.includes("Consumable Purchase"),
          },
          {
            id: "rawMaterialPurchase",
            title: "Raw Material Purchase",
            type: "item",
            url: "/dashboard/sales/rawmaterialpurchase",
            isAccessible: keys.includes("Raw Material Purchase"),
          },
          {
            id: "URD Purchase",
            title: "URD Purchase",
            type: "item",
            url: "/dashboard/sales/urdpurchase",
            isAccessible: keys.includes("URD Purchase"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-4",
      },
      {
        id: "purchaseReturn",
        title: "Purchase Return",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Metal Purchase Return") ||
          keys.includes("Jewellery Purchase Return") ||
          keys.includes("Jewellery Purchase Return (Artician Return)") ||
          keys.includes("Consumable Return") ||
          keys.includes("Raw Material Purchase Return"),
        children: [
          {
            id: "metalPurchaseReturn",
            title: "Metal Purchase Return",
            type: "item",
            url: "/dashboard/sales/metalpurchasereturn",
            isAccessible: keys.includes("Metal Purchase Return"),
          },
          {
            id: "jewelleryPurchaseReturn",
            title: "Jewellery Purchase Return",
            type: "item",
            url: "/dashboard/sales/jewellerypurchasereturn",
            isAccessible: keys.includes("Jewellery Purchase Return"),
          },
          {
            id: "jewelleryArticianReturn",
            title: "Jewellery Purchase Return (Artician return)",
            type: "item",
            url: "/dashboard/sales/articianjewellerypurchasereturn",
            isAccessible: keys.includes(
              "Jewellery Purchase Return (Artician Return)"
            ),
          },
          {
            id: "consumablePurchaseReturn",
            title: "Consumable Purchase Return",
            type: "item",
            url: "/dashboard/sales/consumablepurchasereturn",
            isAccessible: keys.includes("Consumable Return"),
          },
          {
            id: "rawMaterialPurchaseReturn",
            title: "Raw Material Purchse Return",
            type: "item",
            url: "/dashboard/sales/rawmaterialpurchasereturn",
            isAccessible: keys.includes("Raw Material Purchase Return"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-5",
      },
      // {
      //   id: "StockJournalVoucher",
      //   title: "Stock Journal Voucher",
      //   type: "collapse",
      //   icon: "play_circle_outline",
      //   isAccessible : true,
      //   // isAccessible : keys.includes('Stock Journal Voucher') || keys.includes('Stock Journal Voucher'),

      //   children: [
      //     {
      //       id: "StockArticianIssueMetal",
      //       title: "Artician Issue Metal",
      //       type: "item",
      //       url: "/dashboard/sales/stockarticianissuemetal",
      //       isAccessible : true,
      //       // isAccessible : keys.includes('Sales Job Work Info'),

      //     },
      //     {
      //       id: "StockJewelleryArticianReceive",
      //       title: "Jewellery (Artician Receive)",
      //       type: "item",
      //       url: "/dashboard/sales/stockjewelpurchaseartician",
      //       isAccessible : true,
      //       // isAccessible : keys.includes('Issue to Hallmark'),
      //     },
      //     {
      //       id: "StockArticianReturnMetal",
      //       title: "Artician Return Metal",
      //       type: "item",
      //       url: "/dashboard/sales/stockarticianreturnmrtal",
      //       isAccessible : true,
      //       // isAccessible : keys.includes('Sales Domestic Info'),
      //     },

      //   ],
      // },
      // {
      //   type: "divider",
      //   id: "divider-6",
      // },
      {
        id: "metal",
        title: "Metal (I/R)",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Jobwork Metal Receive") ||
          keys.includes("Jobwork Metal Return") ||
          keys.includes("Artician Issue Metal") ||
          keys.includes("Artician Return Metal"),
        children: [
          {
            id: "JobworkMetalReceive",
            title: "Jobwork Metal Receive",
            type: "item",
            url: "/dashboard/sales/jobworkmetalreceive",
            isAccessible: keys.includes("Jobwork Metal Receive"),
          },
          {
            id: "JobworkMetalReturn",
            title: "Jobwork Metal Return",
            type: "item",
            url: "/dashboard/sales/jobworkmetalreturn",
            isAccessible: keys.includes("Jobwork Metal Return"),
          },
          {
            id: "ArticianIssue",
            title: "Artician Issue Metal",
            type: "item",
            url: "/dashboard/sales/articianissue",
            isAccessible: keys.includes("Artician Issue Metal"),
          },
          {
            id: "ArticianReturn",
            title: "Artician Return Metal",
            type: "item",
            url: "/dashboard/sales/articianreturn",
            isAccessible: keys.includes("Artician Return Metal"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "rapairing",
        title: "Repairing",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Repairing Received From the Customer") ||
          keys.includes("Repaired Jewellery return to the customer") ||
          keys.includes("Repairing Issued to the Jobworker") ||
          keys.includes("Repaired Jewellery return from the job worker"),
        children: [
          {
            id: "repairingrecfromcust",
            title: "Repairing Received from the customer",
            type: "item",
            url: "/dashboard/sales/repairingrecfromcust",
            isAccessible: keys.includes("Repairing Received From the Customer"),
          },
          {
            id: "repairedreturntocust",
            title: "Repaired Jewellery return to the customer",
            type: "item",
            url: "/dashboard/sales/repairedjewelreturncust",
            isAccessible: keys.includes(
              "Repaired Jewellery return to the customer"
            ),
          },
          {
            id: "repairingissuetocust",
            title: "Repairing Issued to the Jobworker",
            type: "item",
            url: "/dashboard/sales/repairedisstojobwor",
            isAccessible: keys.includes("Repairing Issued to the Jobworker"),
          },
          {
            id: "repairedreturntocustoth",
            title: "Repaired Jewellery Return From the Job Worker",
            type: "item",
            url: "/dashboard/sales/repairedjewelreturnjobwork",
            isAccessible: keys.includes(
              "Repaired Jewellery return from the job worker"
            ),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-8",
      },
      {
        id: "rateFix",
        title: "Rate Fix",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Rate Fix Client") ||
          keys.includes("Rate Fix Vendor") ||
          keys.includes("Rate Match"),
        children: [
          {
            id: "clientRateFix",
            title: "Client",
            type: "item",
            url: "/dashboard/sales/ratefixclient",
            isAccessible: keys.includes("Rate Fix Client"),
          },
          {
            id: "vendorRateFix",
            title: "Vendor",
            type: "item",
            url: "/dashboard/sales/ratefixvendor",
            isAccessible: keys.includes("Rate Fix Vendor"),
          },
          {
            id: "ratematch",
            title: "Rate Match",
            type: "item",
            url: "/dashboard/sales/ratematch",
            isAccessible: keys.includes("Rate Match"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-9",
      },
      {
        id: "Melting",
        title: "Melting",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Melting Issue") || keys.includes("Melting Issue"),
        children: [
          {
            id: "Melting-Issue",
            title: "Melting Issue",
            type: "item",
            url: "/dashboard/sales/meltingissue",
            isAccessible: keys.includes("Melting Issue"),
          },
          {
            id: "Melting-Receive",
            title: "Melting Receive",
            type: "item",
            url: "/dashboard/sales/meltingreceive",
            isAccessible: keys.includes("Melting Issue"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-10",
      },
      {
        id: "Other",
        title: "Other",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Create Estimate") ||
          keys.includes("Consumable Consumption") ||
          keys.includes("Opening Trial Balance") ||
          keys.includes("Stone Assortment") ||
          keys.includes("Sales of Consumable") ||
          keys.includes("Send of Reproduction"),
        children: [
          {
            id: "createEstimate",
            title: "Create Estimate",
            type: "item",
            url: "/dashboard/sales/createestimate",
            isAccessible: keys.includes("Create Estimate"),
          },
          {
            id: "toolsConsumption",
            title: "Consumable Consumption",
            type: "item",
            url: "/dashboard/sales/toolsconsumption",
            isAccessible: keys.includes("Consumable Consumption"),
          },
          {
            id: "Opening-Trial-Balance",
            title: "Opening Trial Balance",
            type: "item",
            url: "/dashboard/sales/OpningTriBlan",
            isAccessible: keys.includes("Opening Trial Balance"),
          },
          {
            id: "Stone-Assortment",
            title: "Stone Assortment",
            type: "item",
            url: "/dashboard/sales/stoneassortment",
            isAccessible: keys.includes("Stone Assortment"),
          },
          {
            id: "salesconsumable",
            title: "Sales Consumable",
            type: "item",
            url: "/dashboard/sales/salesconsumable",
            isAccessible: keys.includes("Sales of Consumable"),
          },
          {
            id: "sendofreproduction",
            title: "Send of Reproduction",
            type: "item",
            url: "/dashboard/sales/sendofreproduction",
            isAccessible: keys.includes("Send of Reproduction"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-11",
      },
    ];
    return dataArr;
  }
}

export default salesPurchaseNavConfig;
