class taggingNavConfig {
  static taggingNavConfigArr() {
    const roleOfUser = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null;
    const module = roleOfUser ? roleOfUser["Tagging"] : null;
    const keys = module ? Object.keys(module) : [];
    const isLocation = localStorage.getItem('isLocation') ? JSON.parse(localStorage.getItem('isLocation')) : "";

    const dataArr = [
      {
        id: "home",
        title: "Tagging List",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/stock",
        isAccessible: keys.includes("Tagging List"),
      },
      {
        type: "divider",
        id: "divider-0",
      },
      {
        id: "transfer",
        title: "Transfer",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/stock?transferstock",
        isAccessible: keys.includes("Transfer")  && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "accepttransfer",
        title: "Accept Transfer",
        type: "item",
        url: "/dashboard/stock/accepttransferstock",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Accept Transfer") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-2",
      },
      // {
      //   id: "tagmaking",
      //   title: "Tag Making (MIX)",
      //   type: "item",
      //   url: "/dashboard/stock?tagmakingmix",
      //   icon: "play_circle_outline",
      // },
      // {
      //   type: "divider",
      //   id: "divider-3",
      // },
      {
        id: "tagmaklot",
        title: "Tag Making (LOT)",
        type: "item",
        url: "/dashboard/stock?tagmakinglot",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Tag Making (LOT)") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-4",
      },
      {
        id: "regenbarcode",
        title: "Re-Generate Barcode",
        type: "item",
        url: "/dashboard/tagging/regenbarcode",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Re-Generate Barcode") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-5",
      },
      // {
      //   id: "groupmerging",
      //   title: "Group Merging",
      //   type: "item",
      //   url: "/dashboard/stock?groupmerging",
      //   icon: "play_circle_outline",
      // },
      // {
      //   type: "divider",
      //   id: "divider-6",
      // },
      {
        id: "createpacket",
        title: "Create Packet",
        type: "item",
        url: "/dashboard/tagging/createpacket",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Create Packet") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "packingslip",
        title: "Packing Slip",
        type: "item",
        url: "/dashboard/tagging/packingslip",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Packing Slip") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-8",
      },
      {
        id: "packetprint",
        title: "Packet Print",
        type: "item",
        url: "/dashboard/tagging/printpacket",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Packet Print") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-9",
      },
      {
        id: "unreserved",
        title: "Unreserved",
        type: "item",
        url: "/dashboard/tagging/unreserved",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Unreserved") && isLocation === 0,
      },
      {
        type: "divider",
        id: "divider-10",
      },
    ];
    return dataArr;
  }
}

export default taggingNavConfig;
