import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Link,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import axios from "axios";
import Config from "app/fuse-configs/Config";
import jwtService from "app/services/jwtService";
import { setUserData } from "app/auth/store/actions";
import handleError from "../ErrorComponent/ErrorComponent";
import * as Actions from "app/store/actions";

import Loader from "../Loader/Loader";
import NavbarSetting from "app/main/NavbarSetting/NavbarSetting";
// import GoogleIcon from '@material-ui/icons/Google'; // Example icon, add more as needed

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";



const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  background: {
    width: "100%",
    height: "100%",
    zIndex: -1,
    content: '""',
    opacity: 0.24,
    position: "fixed",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage: "url('https://assets.minimals.cc/public/assets/background/background-3-blur.webp')",
  },
  card: {
    width: "100%",
    maxWidth: 400,
    padding: theme.spacing(3),
    boxShadow: 'none !important',
    textAlign: "center",
    backgroundColor: theme.palette.background.paper, // Make sure card background is opaque
    borderRadius: 16,
  },
  logo: {
    width: 100,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 20,
    fontWeight: 600,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5),
    fontWeight: "bold",
    borderRadius: 8
  },
  link: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  socialLoginContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  socialIcon: {
    color: "#888",
  },

  customTextField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 16,
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 16,
    },
  },

}));

function Login() {
  const classes = useStyles();

  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");

  const [userNameError, setUnameErr] = useState(false);
  const [pwdError, setPwdErr] = useState(false);

  const [userNameErrTxt, setUnameErrTxt] = useState("");
  const [pwdErrorTxt, setPwdErrTxt] = useState("");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState();

  useEffect(() => {
    NavbarSetting("Login", dispatch);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIp();
    getAllStiteSettingData();
  }, []);

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://jsonip.com/");
    const data = await response.json();
    // Set the IP address to the constant ip
    localStorage.setItem("ip", data.ip);
    setIp(data.ip);
  };

  function getAllStiteSettingData() {
    axios
      .get(Config.getCommonUrl() + "api/siteSetting/")
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response);
          var apiRes = JSON.stringify(response.data.data);
          localStorage.setItem("siteSetting", apiRes);
        } else {
          localStorage.setItem("siteSetting", []);
        }
      })
      .catch(function (error) {
        localStorage.setItem("siteSetting", []);
        handleError(error, dispatch, { api: "siteSetting" });
      });
  }

  function emailValidation() {
    var usernameRegex = /^[a-zA-Z0-9]+$/;
    if (!userName || usernameRegex.test(userName) === false) {
      setUnameErr(true);
      setUnameErrTxt("User name is not valid");
      return false;
    }
    return true;
  }

  function password_validate() {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!pwd || regex.test(pwd) === false) {
      setPwdErr(true);
      setPwdErrTxt(
        "Password must have Uppercase, Lowercase, special character,number and limit 8 must be required"
      );
      return false;
    }
    return true;
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "userName") {
      setUnameErr(false);
      setUnameErrTxt("");
      setUserName(value);
    } else {
      setPwdErr(false);
      setPwdErrTxt("");
      setPwd(value);
    }
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (emailValidation() && password_validate()) {
      signInWithEmailAndPassword(userName, pwd);
    } else {
      dispatch(
        Actions.showMessage({ message: "Please enter valid credentials" })
      );
    }
  }

  const tempUser = {
    uuid: "XgbuVEXBU5gtSKdbQRP1Zbbby1i1",
    from: "custom-db",
    password: "admin",
    role: "admin",
    data: {
      displayName: userName,
      photoURL: "assets/images/avatars/Abbott.jpg",
      email: "admin",
      settings: {
        layout: {
          style: "layout1",
          config: {
            scroll: "content",
            navbar: {
              display: true,
              // folded  : true, to always open false, default is false
              position: "left",
            },
            toolbar: {
              display: true,
              style: "fixed",
              position: "above",
            },
            footer: {
              display: true,
              style: "fixed",
              position: "below",
            },
            mode: "fullwidth",
          },
        },
        customScrollbars: false,
        theme: {
          main: "default",
          navbar: "default",
          toolbar: "default",
          footer: "default",
        },
      },
      shortcuts: ["calendar", "mail", "contacts"],
    },
  };

  const signInWithEmailAndPassword = (email, password) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const ipAddress = localStorage.getItem("ip");
      axios.defaults.headers.common["x-real-ip"] = ipAddress;
      axios.defaults.headers.common["x-forwarded-for"] = ipAddress;
      axios
        .post(Config.getCommonUrl() + "admin/login", {
          username: email,
          password: password,
        })
        .then((response) => {
          if (response.data.success === true) {
            console.log("resolve", response.data.id);
            const prevAuth = localStorage.getItem("userId")
            console.log(prevAuth, "userId")
            if (!prevAuth || (prevAuth && prevAuth == response.data.id)) {
              jwtService.setSession(response.data.authToken, null, null);
              jwtService.setRefreshToken(response.data.refreshToken);
              getUserPermissions();
              localStorage.setItem("userName", email);
              localStorage.setItem("userId", response.data.id);
              localStorage.setItem("isSuperAdmin", response.data.is_super_admin);
              localStorage.setItem("designation", response.data.designation);
              localStorage.setItem(
                "adminSignature",
                response.data.admin_signature
              );
              if (response.data.location !== null) {
                localStorage.setItem("isDesigner", true);

                setTimeout(() => {
                  setLoading(false);
                  dispatch(setUserData(tempUser));
                  dispatch({
                    type: LOGIN_SUCCESS,
                  });
                }, 800);
              } else {
                localStorage.setItem("isDesigner", false);
                setTimeout(() => {
                  setLoading(false);
                  dispatch(setUserData(tempUser));
                  dispatch({
                    type: LOGIN_SUCCESS,
                  });
                }, 800);
              }
            } else {
              window.location.reload()
            }
            // setApiResErr(false);
            // setApiResErrTxt("");
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
          handleError(error, dispatch, {
            api: "admin/login",
            body: {
              username: email,
              password: password,
            },
          });
          // if (!error.response) {//network issue
          //   console.log(error.message);
          //   dispatch(Actions.showMessage({message: error.message}));
          // } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: error.response?.data.message,
          });
          //   setApiResErr(true);
          //   dispatch(Actions.showMessage({message: "Please enter valid credentials"}));
          //   // setApiResErrTxt(error.response.data.message);
          // }
        });
    });
  };

  function getUserPermissions() {
    axios
      .get(Config.getCommonUrl() + "admin/permission/user")
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response.data.data);
          localStorage.setItem(
            "permission",
            JSON.stringify(response.data.data)
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        handleError(error, dispatch, { api: "admin/permission/user" });
      });
  }
  return (
    <div className="custom-login">
      <div className={classes.root}>
        <div className={classes.background}></div> {/* Background layer */}
        <Card className={classes.card} >
          <CardContent>
            <img
              src={Config.getvkjLogo()}
              alt="Logo"
              className={classes.logo}
            />
            <Typography className={classes.title}>
              Sign in to your account
            </Typography>
            <Typography variant="body2" className={classes.link}>
              Don’t have an account? <Link href="/register" style={{ color: "#fda92d" }}>Get started</Link>
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField

                className={classes.customTextField}
                label="Email address"
                name="userName"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={userName}
                onChange={handleInputChange}
              />
              <TextField
                className={classes.customTextField}

                label="Password"
                name="password"
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={pwd}
                onChange={handleInputChange}
              />
              <div style={{ textAlign: "end", margin: '10px 0px' }}>

                <Link href="/forgot-password" variant="body2" className={classes.link} style={{ color: "#fda92d" }}>
                  Forgot password?
                </Link>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={'primary-btn'}
                disabled={loading}
              >
                {loading ? "Logging in..." : "Sign In"}
              </Button>
            </form>


          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Login;
