
// import React from 'react';
import BlankMobilePage from './BlankMobilePage';

export const BlankMobilePageAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/dashboard/mobileappadmin',
            component: BlankMobilePage
        }
    ]
};
