import React from "react";
export const MisJobworkersReportAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/reports/misjobworkersreport",
      component: React.lazy(() => import("./MisJobworkersReport")),
    },
  ],
};
