import React from 'react';
export const MakeaBunchAppConfig = {
    settings: {
        layout: {
            config: {
                // navbar        : {
                //     display: false
                // },
                // toolbar       : {
                //     display: true
                // },
                // footer        : {
                //     display: false
                // },
                // leftSidePanel : {
                //     display: false
                // },
                // rightSidePanel: {
                //     display: false
                // }
            }
        }
    },
    routes  : [
        {
            path     : '/dashboard/production/makeabunch',
            component: React.lazy(() => import('./MakeaBunch'))
        }
    ]
};
