// import {AnalyticsDashboardAppConfig} from './dashboards/analytics/AnalyticsDashboardAppConfig';
import { ItemTypeAppConfig } from "./Masters/ItemType/ItemTypeAppConfig";
import { StockGroupAppConfig } from "./Masters/StockGroup/StockGroupAppConfig";
import { BlankPageAppConfig } from "./Masters/BlankPage/BlankPageAppConfig";
import { StockNameAppConfig } from "./Masters/StockName/StockNameAppConfig";
import { SystemUserAppConfig } from "./Masters/SystemUser/SystemUserAppConfig";
import { EmployeeAppConfig } from "./Masters/Employee/EmployeeAppConfig";
import { AddEmployeeAppConfig } from "./Masters/Employee/AddEmployee/AddEmployeeAppConfig";
import { ClientListAppConfig } from "./Masters/ClientList/ClientListAppConfig";
import { AddClientAppConfig } from "./Masters/ClientList/AddClient/AddClientAppConfig";
import { ProductCategoryAppConfig } from "./Masters/ProductCategory/ProductCategoryAppConfig";
import { HSNMasterAppConfig } from "./Masters/HSNMasters/HSNMasterAppConfig";
import { DepartmentAppConfig } from "./Masters/Department/DepartmentAppConfig";
import { ProcessAppConfig } from "./Masters/Process/ProcessAppConfig";
import { SalesRateProfileAppConfig } from "./Masters/SalesRateProfile/SalesRateProfileAppConfig";
import { ViewSalesRateProfileAppConfig } from "./Masters/SalesRateProfile/SubView/ViewSalesRateProfileAppConfig";
import { VendorAppConfig } from "./Masters/Vendor/VendorAppConfig";
import { AddVendorAppConfig } from "./Masters/Vendor/AddVendor/AddVendorAppConfig";
import { EditVendorAppCofig } from "./Masters/Vendor/EditVendor/EditVendorAppCofig";
import { EditClientAppConfig } from "./Masters/ClientList/EditClient/EditClientAppConfig";
import { BTOCClientAppConfig } from "./Masters/BTOCClient/BTOCClientAppConfig";
import { UserRoleAppConfig } from "./Masters/UserRole/UserRoleConfig";
import { JobWorkerAppConfig } from "./Masters/JobWorker/JobWorkerAppConfig";
import { SalesMenAppConfig } from "./Masters/SalesMen/SalesMenAppConfig";
import { AddSalesManAppConfig } from "./Masters/SalesMen/AddSalesMan/AddSalesManAppConfig";
import { EditSalesManAppConfig } from "./Masters/SalesMen/EditSalesMan/EditSalesManAppConfig";
import { AddJobWorkerAppConfig } from "./Masters/JobWorker/AddJobWorker/AddJobWorkerAppConfig";
import { EditJobWorkerAppConfig } from "./Masters/JobWorker/EditJobWorker/EditJobWorkerAppConfig";
import { WorkStationAppConfig } from "./Masters/WorkStation/WorkStationAppConfig";
import { AddWorkStationAppConfig } from "./Masters/WorkStation/addWorkStation/AddWorkStationAppConfig";
import { EditWorkStationAppConfig } from "./Masters/WorkStation/EditWorkStation/EditWorkStationAppConfig";
import { SalesBlankPageAppConfig } from "./SalesPurchase/BlankPage/SalesBlankPageAppConfig";
import { RateFixClientAppConfig } from "./SalesPurchase/RateFixClient/RateFixClientAppConfig";
import { AddRateFixClientAppConfig } from "./SalesPurchase/RateFixClient/AddNewRateFixClient/AddRateFixClientAppConfig";
import { RateFixVendorAppConfig } from "./SalesPurchase/RateFixVendor/RateFixVendorAppConfig";
import { AddRateFixVendorAppConfig } from "./SalesPurchase/RateFixVendor/AddNewRateFixVendor/AddRateFixVendorAppConfig";
import { CreateAccountAppConfig } from "./Account/CreateAccount/CreateAccountAppConfig";
import { VoucherListAppConfig } from "./Account/Voucher/VoucherListAppConfig";
import { EditVoucherAppConfig } from "./Account/Voucher/EditVoucher/EditVoucherAppConfig";
import { VoucherEntryAppConfig } from "./Account/VoucherEntry/VoucherEntryAppConfig";
import { MetalPurchaseAppConfig } from "./SalesPurchase/MetalPurchase/MetalPurchaseAppConfig";
import { JewelleryPurchaseAppConfig } from "./SalesPurchase/JewelleryPurchase/JewelleryPurchaseAppConfig";
import { JewelPurchaseArticianAppConfig } from "./SalesPurchase/JewelPurchaseArtician/JewelPurchaseArticianAppConfig";
import { ExportMetalPurchaseAppConfig } from "./SalesPurchase/ExportMetalPurchase/ExportMetalPurchaseAppConfig";
import { ConsumablePurchaseAppConfig } from "./SalesPurchase/ConsumablePurchase/ConsumablePurchaseAppConfig";
import { OpningTriBlanAppConfing } from "./SalesPurchase/OpningTriBlan/OpningTriBlanAppConfing";
import { StoneAssortmentAppConfing } from "./SalesPurchase/StoneAssortment/StoneAssortmentAppConfig";
import { MeltingIssueAppConfig } from "./SalesPurchase/MeltingIssue/MeltingIssueAppConfig";
import { MeltingReceiveAppConfig } from "./SalesPurchase/MeltingReceive/MeltingReceiveAppConfig";
import { AddMeltingReceiveAppConfig } from "./SalesPurchase/MeltingReceive/AddMeltingReceive/AddMeltingReceiveAppConfig";
import { AddMeltingIssueAppConfig } from "./SalesPurchase/MeltingIssue/AddMeltingIssue/AddMeltingIssueAppConfig";
import { RawMaterialPurchaseAppConfig } from "./SalesPurchase/RawMaterialPurchase/RawMaterialPurchaseAppConfig";
import { MetalPurchaseReturnAppConfig } from "./SalesPurchase/MetalPurchaseReturn/MetalPurchaseReturnAppConfig";
import { CreatePacketAppConfig } from "./Tagging/CreatePacket/CreatePacketAppConfig";
import { PackingSlipAppConfig } from "./Tagging/PackingSlip/PackingSlipAppConfig";
import { EditPackingSlipAppConfig } from "./Tagging/PackingSlip/EditPackingSlip/EditPackingSlipAppConfig";
import { ReGenBarcodeAppConfig } from "./Tagging/ReGenBarcode/ReGenBarcodeAppConfig";
import { TagMakingLotAppConfig } from "./Tagging/TagMakingLot/TagMackingLotAppConfig";
import { TagMakingMixAppConfig } from "./Tagging/TagMakingMix/TagMakingMixAppConfig";
import { UnreservedAppConfig } from "./Tagging/Unreserved/UnreservedAppConfig";
import { ConsumablePurchaseReturnAppConfig } from "./SalesPurchase/ConsumablePurchaseReturn/ConsumablePurchaseReturnAppConfig";
import { RawMaterialPurchaseReturnAppConfig } from "./SalesPurchase/RawMaterialPurchaseReturn/RawMaterialPurchaseReturnAppConfig";
import { JewelleryPurchaseReturnAppConfig } from "./SalesPurchase/JewelleryPurchaseReturn/JewelleryPurchaseReturnAppConfig";
import { JewelPurchaseArticianReturnAppConfig } from "./SalesPurchase/JewelPurchaseArticianReturn/JewelPurchaseArticianReturnAppConfig";
import { JobworkMetalReceiveIRAppConfig } from "./SalesPurchase/JobworkMetalReceiveIR/JobworkMetalReceiveIRAppConfig";
import { JobworkMetalReturnIRAppConfig } from "./SalesPurchase/JobworkMetalReturnIR/JobworkMetalReturnIRAppConfig";
import { ArticianReturnIRAppConfig } from "./SalesPurchase/ArticianReturnIR/ArticianReturnIRAppConfig";
import { ArticianIssueIRAppConfig } from "./SalesPurchase/ArticianIssueIR/ArticianIssueIRAppConfig";
import { EditDesignAppConfig } from "./Design/DesignList/EditDesign/EditDesignAppConfig";
// import { ReceiveWorkerAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Master/ReceiveWorkerMaster/ReceiveWorkerMasterAppConfig";
import { MainDesignAppConfig } from "./Design/MainDesignPage/MainDesignAppConfig";
import { RepairingRecfromCustAppConfig } from "./SalesPurchase/RepairingRecfromCust/RepairingRecfromCustAppConfig";
import { RepairedJewelReturnCustAppConfig } from "./SalesPurchase/RepairedJewelReturnCust/RepairedJewelReturnCustAppConfig";
import { LedgerRateAppConfig } from "./Masters/LedgerRate/LedgerRateAppConfig";
import { SalesDomesticAppConfig } from "./SalesPurchase/SalesDomestic/SalesDomesticAppConfig";
import { SalesDomesticInfoAppConfig } from "./SalesPurchase/SalesDomesticInfo/SalesDomesticInfoAppConfig";
import { SalesJobworkAppConfig } from "./SalesPurchase/SalesJobwork/SalesJobworkAppConfig";
import { SalesJobworkInfoAppConfig } from "./SalesPurchase/SalesJobworkInfo/SalesJobworkInfoAppConfig";
import { GoldRateAppConfig } from "./Masters/GoldRate/GoldRateAppConfig";
import { MetalLedStmtAppConfig } from "./Reports/MetalLedgerStatement/MetalLedStmtAppConfig";
import { BarcodeGeneratorReportAppConfig } from "./Reports/BarcodeGeneratorReport/BarcodeGeneratorReportAppConfig";
import { BodarCodeGenerationAccAndLossAppConfig } from "./Reports/BodarCodeGenerationAccAndLoss/BodarCodeGenerationAccAndLossAppConfig";
import { PackingListReportAppConfig } from "./Reports/PackingListReport/PackingListReportAppConfig";
import { PackingListWithHUIDAppConfig } from "./Reports/PackingListWithHUID/PackingListWithHUIDAppConfig";
import { PartyWiseMetalAccountBalanceAppConfing } from "./Reports/PartyWiseMetalAccountBalance/PartyWiseMetalAccountBalanceAppConfing";
import { MisFactoryStockAppconfig } from "./Reports/MISFactoryStock/MisFactoryStockAppconfig";
import { FineGoldReportAppConfig } from "./Reports/FineGoldReport/FineGoldReportAppConfig";
import { GSTROneSummaryReportAppConfig } from "./Reports/GSTROneSummaryReport/GSTROneSummaryReportAppConfig";
import { DepartmentTransferSummaryAppConfig } from "./Reports/DepartmentTransferSummary/DepartmentTransferSummaryAppConfig";
import { AccountLedStmtAppConfig } from "./Reports/AccountLedgerStatement/AccountLedStmtAppConfig";
import { JobworkStockStmtAppConfig } from "./Reports/JobworkStockStatement/JobworkStockStmtAppConfig";
import { RepairedIssToJobWorAppConfig } from "./SalesPurchase/RepairedIssToJobWorker/RepairedIssToJobWorAppConfig";
import { RepairedJewelReturnJobWorkerAppConfig } from "./SalesPurchase/RepairedJewelReturnJobWorker/RepairedJewelReturnJobWorkerAppConfig";
import { SalesReturnJobworkAppConfig } from "./SalesPurchase/SalesReturnJobwork/SalesReturnJobworkAppConfig";
import { SalesReturnDomesticAppConfig } from "./SalesPurchase/SalesReturnDomestic/SalesReturnDomesticAppConfig";
import { HallmarkAppConfig } from "./Hallmark/HallmarkPage/HallmarkAppConfig";
import { AcceptHmTransferAppConfig } from "./Hallmark/AcceptTransfer/AcceptHmTransferAppConfig";
import { IssueToHallmarkAppConfig } from "./Hallmark/IssueToHallmark/IssueToHallmarkAppConfig";
import { RecFromHallmarkAppConfig } from "./Hallmark/RecFromHallmark/RecFromHallmarkAppConfig";
import { EditPacketAppConfig } from "./Tagging/CreatePacket/EditPacket/EditPacketAppConfig";
import { AddMetalPurchaseAppConfig } from "./SalesPurchase/MetalPurchase/AddMetalPurchase/AddMetalPurchaseAppConfig";
import { AddJewellaryPurchaseAppConfig } from "./SalesPurchase/JewelleryPurchase/AddJewellaryPurchase/AddJewellaryPurchaseAppConfig";
import { AddJewelPurchaseArticianAppConfig } from "./SalesPurchase/JewelPurchaseArtician/AddJewelPurchaseArtician/AddJewelPurchaseArticianAppConfig";
import { AddExportMetalPurchaseAppConfig } from "./SalesPurchase/ExportMetalPurchase/AddExportMetalPurchase/AddExportMetalPurchaseAppConfig";
import { AddConsumablePurchaseAppConfig } from "./SalesPurchase/ConsumablePurchase/AddConsumablePurchase/AddConsumablePurchaseAppConfig";
import { AddRawMaterialPurchaseAppConfig } from "./SalesPurchase/RawMaterialPurchase/AddRawMaterialPurchase/AddRawMaterialPurchaseAppConfig";
import { TaggingRateProfileAppConfig } from "./Masters/TaggingRateProfile/TaggingRateProfileAppConfig";
import { ViewTaggingRateProfileAppConfig } from "./Masters/TaggingRateProfile/SubView/ViewTaggingRateProfileAppConfig";
import { FinishPurityAppConfig } from "./Masters/FinishPurityMaster/FinishPurityAppConfig";
import { StateAppConfig } from "./Masters/StateMaster/StateAppConfig";
import { WeightToleranceAppConfig } from "./Masters/WeightTolerance/WeightToleranceAppConfig";
import { JobwokerVendorRateProfAppConfig } from "./Masters/JobworkerVendorRateProf/JobwokerVendorRateProfAppConfig";
import { ViewJwVendRateProfAppConfig } from "./Masters/JobworkerVendorRateProf/SubView/ViewJwVendRateProfAppConfig";
import { AddMetalPurchaseReturnAppConfig } from "./SalesPurchase/MetalPurchaseReturn/AddMetalPurchaseReturn/AddMetalPurchaseReturnAppConfig";
import { AddJewellaryPurchaseReturnAppConfig } from "./SalesPurchase/JewelleryPurchaseReturn/AddJewellaryPurchaseReturn/AddJewellaryPurchaseReturnAppConfig";
import { AddConsumablePurcReturnAppConfig } from "./SalesPurchase/ConsumablePurchaseReturn/AddConsumablePurcReturn/AddConsumablePurcReturnAppConfig";
import { AddRawMatPurcReturnAppConfig } from "./SalesPurchase/RawMaterialPurchaseReturn/AddRawMaterialPurchaseReturn/AddRawMatPurcReturnAppConfig";
import { AddJobworkMetalReceiveIRAppConfig } from "./SalesPurchase/JobworkMetalReceiveIR/AddJobworkMetalReceiveIR/AddJobworkMetalReceiveIRAppConfig";
import { AddJobworkMetalReturnIRAppConfig } from "./SalesPurchase/JobworkMetalReturnIR/AddJobworkMetalReturnIR/AddJobworkMetalReturnIRAppConfig";
import { AddArticianIssueIRAppConfig } from "./SalesPurchase/ArticianIssueIR/AddArticianIssueIR/AddArticianIssueIRAppConfig";
import { AddArticianReturnIRAppConfig } from "./SalesPurchase/ArticianReturnIR/AddArticianReturnIR/AddArticianReturnIRAppConfig";
import { AddRepairingRecfromCustAppConfig } from "./SalesPurchase/RepairingRecfromCust/AddRepairingRecfromCust/AddRepairingRecfromCustAppConfig";
import { AddRepairedJewelReturnCustAppConfig } from "./SalesPurchase/RepairedJewelReturnCust/AddRepairedJewelReturnCust/AddRepairedJewelReturnCustAppConfig";
import { AddRepairedIssToJobWorkerAppConfig } from "./SalesPurchase/RepairedIssToJobWorker/AddRepairedIssToJobWorker/AddRepairedIssToJobWorkerAppConfig";
import { AddRepairedJewelReturnJobWorkerApConfig } from "./SalesPurchase/RepairedJewelReturnJobWorker/AddRepairedJewelReturnJobWorker/AddRepairedJewelReturnJobWorkerApConfig";
import { VoucherHistoryAppConfig } from "./Account/VoucherHistory/VoucherHistoryAppConfing";
import { AddJewelPurchaseArticianReturnAppConfig } from "./SalesPurchase/JewelPurchaseArticianReturn/AddJewelPurchaseArticianReturn/AddJewelPurchaseArticianReturnAppConfig";
import { AddSalesDomesticAppConfig } from "./SalesPurchase/SalesDomestic/AddSalesDomestic/AddSalesDomesticAppConfig";
import { AddSalesDomesticInfoAppConfig } from "./SalesPurchase/SalesDomesticInfo/AddSalesDomesticInfo/AddSalesDomesticInfoAppConfig";
import { AddSalesJobworkAppConfig } from "./SalesPurchase/SalesJobwork/AddSalesJobwork/AddSalesJobworkAppConfig";
import { AddSalesJobworkInfoAppConfig } from "./SalesPurchase/SalesJobworkInfo/AddSalesJobworkInfo/AddSalesJobworkInfoAppConfig";
import { AddSalesReturnDomesticAppConfig } from "./SalesPurchase/SalesReturnDomestic/AddSalesReturnDomestic/AddSalesReturnDomesticAppConfig";
import { AddSalesReturnJobworkAppConfig } from "./SalesPurchase/SalesReturnJobwork/AddSalesReturnJobwork/AddSalesReturnJobworkAppConfig";
import { RateMatchAppConfig } from "./SalesPurchase/RateMatch/RateMatchAppConfig";
import { LedgerReportAppConfig } from "./Account/LedgerReportGroupWise/LedgerReportAppConfig";
import { LedgerRepAccAppConfig } from "./Account/LedgerReportAccWise/LedgerRepAccAppConfig";
import { HallmarkChargesAppConfig } from "./Masters/HallmarkCharges/HallmarkChargesAppConfig";
import { HallmarkStationAppConfig } from "./Masters/HallmarkStation/HallmarkStationAppConfig";
import { StockAppConfig } from "./Stock/StockList/StockAppConfig";
import { AcceptStockTransAppConfig } from "./Stock/AcceptStockTransfer/AcceptStockTransAppConfig";
import { SalesEstimateAppConfig } from "./SalesPurchase/SalesEstimate/SalesEstimateAppConfig";
import { TempPrintAppConfig } from "./SalesPurchase/TempPrint/TempPrintAppConfig";
import { ReportAppConfig } from "./Reports/Report/ReportAppConfig";
import { OrdersAppConfig } from "./MobileAppAdmin/Orders/OrdersAppConfig";
import { RetailerMasterAppConfig } from "./MobileAppAdmin/RetailerMaster/RetailerMasterAppConfig";
import { CreateCompanyAppConfig } from "./MobileAppAdmin/RetailerMaster/CreateCompany/CreateCompanyAppConfig";
import { UserMasterAppConfig } from "./MobileAppAdmin/UserMaster/UserMasterAppConfig";
import { SalesmanMobAppConfig } from "./MobileAppAdmin/SalesmanMob/SalesmanMobAppConfig";
import { DeletedSalesmanAppConfig } from "./MobileAppAdmin/SalesmanMob/DeletedSalesman/DeletedSalesmanAppConfig";
import { AddUserAppConfig } from "./MobileAppAdmin/UserMaster/AddUser/AddUserAppConfig";
import { AddSalesmanMobAppConfig } from "./MobileAppAdmin/SalesmanMob/AddSalesmanMob/AddSalesmanMobAppConfig";
import { BirthdayListAppConfig } from "./MobileAppAdmin/UserMaster/BirthdayList/BirthdayListAppConfig";
import { DeletedListAppConfig } from "./MobileAppAdmin/UserMaster/Deleteduser/DeletedListAppConfig";
import { DistributorMasterAppConfig } from "./MobileAppAdmin/DistributorMaster/DistributorMasterAppConfig";
import { TeaserDesignAppConfig } from "./MobileAppAdmin/TeaserDesign/TeaserDesignAppConfig";
import { BrandingAppConfig } from "./MobileAppAdmin/Branding/BrandingAppConfig";
import { UpdateBrandingsAppConfig } from "./MobileAppAdmin/Branding/UpdateBrandings/UpdateBrandingsAppConfig";
import { SalesmanAssociationAppConfig } from "./MobileAppAdmin/SalesmanAssociation/SalesmanAssociationAppConfig";
import { CompanyAssociationAppConfig } from "./MobileAppAdmin/CompanyAssociation/CompanyAssociationAppConfig";
import { PushNotificationAppConfig } from "./MobileAppAdmin/PushNotification/PushNotificationAppConfig";
import { MyCatalogueAppConfig } from "./MobileAppAdmin/MyCatalogue/MyCatalogueAppConfig";
import { AddCatalogueAppConfig } from "./MobileAppAdmin/MyCatalogue/AddCatalogue/AddCatalogueAppConfig";
import { NewsAndUpdateAppConfig } from "./MobileAppAdmin/NewsAndUpdate/NewsAndUpdateAppConfig";
import { AddNewsUpdatesAppConfig } from "./MobileAppAdmin/NewsAndUpdate/AddNewsUpdates/AddNewsUpdatesAppConfig";
import { EditCollectionAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Collection/EditDesignJob/EditDesignJobAppConfig";
import { ExhibitionMasterAppConfig } from "./MobileAppAdmin/ExibitionMaster/ExhibitionMasterAppConfig";
import { ProspectiveOrderAppConfig } from "./MobileAppAdmin/ProspectiveOrder/ProspectiveOrderAppConfig";
import { LoginDetailsAppConfig } from "./MobileAppAdmin/LoginDetails/LoginDetailsAppConfig";
import { AddExhibitionAppConfig } from "./MobileAppAdmin/ExibitionMaster/AddExhibition/AddExhibitionAppConfig";
import { ProsOrderDetailAppConfig } from "./MobileAppAdmin/ProspectiveOrder/ProsOrderDetail/ProsOrderDetailAppConfig";
import { LoginSubDetailsAppConfig } from "./MobileAppAdmin/LoginDetails/LoginSubDetails/LoginSubDetailsAppConfig";
import { CreateDistributorAppConfig } from "./MobileAppAdmin/DistributorMaster/CreateDistributor/CreateDistributorAppConfig";
import { BaggingAppConfig } from "./Masters/Bagging/BaggingAppConfing";
import { TypeOfSettingAppConfig } from "./Masters/TypeOfSetting/TypeOfSettingAppConfig";
import { SettingMethodAppConfig } from "./Masters/SettingMethod/SettingMethodAppConfig";
import { OrderDetailsAppConfig } from "./MobileAppAdmin/Orders/OrderDetails/OrderDetailsAppConfig";
import { CategoriesAppConfig } from "./MobileAppAdmin/Categories/CategoriesAppConfig";
import { CmsAppConfig } from "./MobileAppAdmin/CMS/CmsAppConfig";
import { EditCADAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAD/EditCAD/EditCADAppConfig";
import { OtherAccVoucherAppConfig } from "./Account/OtherVoucher/OtherAccVoucherAppConfig";
import { VoucherEditViewAppConfig } from "./Account/VoucherHistory/VoucherEditView/VoucherEditViewAppConfig";
import { DesignerRoleAppConfig } from "./Masters/DesignerRole/DesignerRoleAppConfig";
import { DesignUserAppConfig } from "./Masters/DesignUser/DesignUserAppConfig";
import { DesignerLocationAppConfig } from "./Masters/DesignerLocation/DesignerLocationAppConfig";
import { CreateNewCollectionAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Collection/CreateNewCollection/CreateNewCollectionAppConfig";
import { CreateNewDesignJobAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Collection/CreateNewDesignJob/CreateNewDesignJobAppConfig";
import { CreateNewCADAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAD/CreateNewCAD/CreateNewCADAppConfig";
import { CADRepairingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAD/CADRepairing/CADRepairingAppConfig";
import { CADRejectionReceivedAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAD/CADRejectionReceived/CADRejectionReceivedAppConfig";
import { CAMIssueToWorkerAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAM/CAMIssueToWorker/CAMIssueToWorkerAppConfig";
import { CAMReceiveFromWorkerAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAM/CAMReceiveFromWorker/CAMReceiveFromWorkerAppConfig";
import { CAMReparingAppconfig } from "./Design/ProductDevelopment/ProductSubViews/CAM/CAMRepairing/CAMReparingAppConfig";
import { CAMRejectionReceivedAppConfig } from "./Design/ProductDevelopment/ProductSubViews/CAM/CAMRejectionReceived/CAMRejectionReceivedAppConfig";
import { IsueeMasterFinishingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Master/IsueeMasterFinishing/IsueeMasterFinishingAppConfig";
import { ReceiveFromWorkerMasterAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Master/ReceiveWorkerMaster/ReceiveWorkerMasterAppConfig";
import { MatserRepairingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Master/MasterReparing/MatserRepairingAppConfig";
import { MasterRejectionReceivedAppConfig } from "./Design/ProductDevelopment/ProductSubViews/Master/MasterRejectionReceived/MasterRejectionReceivedAppConfig";
import { IsueeSilverCastingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/SilverCasting/IsueeSilverCasting/IsueeSilverCastingAppConfig";
import { ReceiveFromSilverAppConfig } from "./Design/ProductDevelopment/ProductSubViews/SilverCasting/ReceiveFromSilver/ReceiveFromSilverAppConfig";
import { SilverRepairingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/SilverCasting/SilverRepairing/SilverRepairingAppConfig";
import { SilverRejectionAppConfig } from "./Design/ProductDevelopment/ProductSubViews/SilverCasting/SilverRejection/SilverRejectionAppConfig";
import { IsueeToCromeAppConfig } from "./Design/ProductDevelopment/ProductSubViews/ChromePlating/IsueeToCrome/IsueeToCromeAppConfig";
import { ReceiveFromCromAppConfig } from "./Design/ProductDevelopment/ProductSubViews/ChromePlating/ReceiveFromCrome/ReceiveFromCromAppConfig";
import { CromeRepairingAppconfig } from "./Design/ProductDevelopment/ProductSubViews/ChromePlating/CromeRepairing/CromeRepairingAppConfig";
import { CromeRejectionAppConfig } from "./Design/ProductDevelopment/ProductSubViews/ChromePlating/CromeRejection/CromeRejectionAppConfig";
import { IsueeToMoldAppConfig } from "./Design/ProductDevelopment/ProductSubViews/MoldCutting/IsueeToMold/IsueeToMoldAppConfig";
import { ReceiveFromMoldAppConfig } from "./Design/ProductDevelopment/ProductSubViews/MoldCutting/ReceiveFromMold/ReceiveFromMoldAppConfig";
import { MoldRepairingAppConfig } from "./Design/ProductDevelopment/ProductSubViews/MoldCutting/MoldRepairing/MoldRepairingAppConfig";
import { MoldRejectionAppConfig } from "./Design/ProductDevelopment/ProductSubViews/MoldCutting/MoldRejection/MoldRejectionAppConfig";
import { TagSystemAppConfig } from "./Masters/TagSystem/TagSystemAppConfig";
import { TagPrinterAppConfig } from "./Masters/TagPrinter/TagPrinterAppConfig";
import { CollectionVariantWiseAppConfig } from "./Design/DesignList/CollectionVariant/CollectionVariantWiseAppConfig";
import { CollectionSizeWiseAppConfig } from "./Design/DesignList/CollectionSize/CollectionSizeWiseAppConfig";
import { PrimaryAllocationAppConfig } from "./MobileAppAdmin/PrimaryAllocation/PrimaryAllocationAppConfig";
import { AddPrimaryAllocationAppConfig } from "./MobileAppAdmin/PrimaryAllocation/AddDesign/AddPrimaryAllocationAppConfig";
import { ViewEditEngImgAppConfig } from "./Design/ProductDevelopment/ProductSubViews/EngineeredImage/ViewEditEngImg/ViewEditEngImgAppConfig";
import { CountryAppConfig } from "./Masters/Country/CountryAppConfig";
import { SiteSettingsAppConfig } from "./Masters/SiteSettings/SiteSettingsAppComfig";
import { CityAppConfig } from "./Masters/City/CityAppConfig";
import { ToolsConsumptionAppConfig } from "./SalesPurchase/ToolsConsumption/ToolsConsumptionAppConfig";
import { SendOfReproductionAppConfig } from "./SalesPurchase/SendOfReproduction/SendOfReproductionAppConfig";
import { AddToolsConsumptionAppConfig } from "./SalesPurchase/ToolsConsumption/AddToolsConsumption/AddToolsConsumptionAppConfig";
import { AccountBlankPageAppConfig } from "./Account/AccountBlankPage/AccountBlankPageAppConfig";
import { HallmarkIssuedListkAppConfig } from "./Hallmark/HallmarkIssuedList/HallmarkIssuedListAppConfig";
import { VariantAttributeAppConfig } from "./Masters/VariantAttribute/VariantAttributeAppConfig";
import { AddOrderAppConfig } from "./MobileAppAdmin/Orders/AddOrder/AddOrderAppConfig";
import { OtherLeadAppConfig } from "./MobileAppAdmin/OtherLead/OtherLeadAppConfig";
import { DistributerCatalogueAppConfig } from "./MobileAppAdmin/DistributerCatalogue/DistributerCatalogueAppConfig";
import { AddNewLeadAppConfig } from "./MobileAppAdmin/OtherLead/AddNewLead/AddNewLeadAppConfig";
import { AddDisCatalogueAppConfig } from "./MobileAppAdmin/DistributerCatalogue/AddDisCatalogue/AddDisCatalogueAppConfig";
import { DeletedVoucherAppConfig } from "./Account/DeletedVoucher/DeletedVoucherAppConfig";
import { DistributorDetailViewAppConfig } from "./MobileAppAdmin/Orders/DistributorDetailView/DistributorDetailViewAppConfig";
import { AnniversaryListAppConfig } from "./MobileAppAdmin/UserMaster/AnniversaryList/AnniversaryListAppConfig";
import { ProductionMainAppConfig } from "./Production/ProductionMain/ProductionMainAppConfig";
import { IssueToWorkerAppConfig } from "./Production/ProductionMain/PopUps/IssueToworker/IssueToWorkerAppConfig";
import { AddToLotAppConfig } from "./Production/ProductionMain/PopUps/AddToLot/AddToLotAppConfig";
import { RemoveFromLotAppConfig } from "./Production/ProductionMain/PopUps/RemoveFromLot/RemoveFromLotAppConfig";
import { HallmarkwiseInnAndOutAppConfig } from "./Reports/HallmarkwiseInnAndOut/hallmarkwiseInnAndOutAppConfig";
import { StockTypeWiseDepartmentwiseStoRAppConfig } from "./Reports/StockTypeWiseDepartmentwiseStoR/StockTypeWiseDepartmentwiseStoRAppConfig";
import { ReceiveFromWorkerAppConfig } from "./Production/ProductionMain/PopUps/ReceiveFromWorker/ReceiveFromWorkerAppConfig";
import { MakeaBunchAppConfig } from "./Production/ProductionMain/PopUps/MakeaBunch/MakeaBunchAppConfig";
import { SplitAppConfig } from "./Production/ProductionMain/PopUps/Spit/SplitAppConfig";
import { MergeAppConfig } from "./Production/ProductionMain/PopUps/Merge/MergeAppConfig";
import { MakeATreeAppConfig } from "./Production/ProductionMain/PopUps/MakeATree/MakeATreeAppConfig";
import { BreakATreeAppConfig } from "./Production/ProductionMain/PopUps/BreackATree/BreakATreeAppConfig";
import { RateFixandPurcSlaesListAppConfig } from "./Reports/RateFixandPurchesSAlesList/RateFixandPurcSlaesListAppConfig";
import { AddAReferenceNumber } from "./Production/ProductionMain/PopUps/AddAReferenceNumber/AddAReferenceNumberAppConfig";
import { IssueForAlloyingAppConfig } from "./Production/ProductionMain/PopUps/IssueForAlloying/IssueForAlloyingAppConfig";
import { BlankMobilePageAppConfig } from "./MobileAppAdmin/BlankMobilePage/BlankMobilePageAppConfig";
import { ReportBlankPageAppConfig } from "./Reports/ReportBlankPage/ReportBlankPageAppConfig";
import { SalesConsumableAppConfig } from "./SalesPurchase/SalesConsumable/SalesConsumableAppConfig";
import { AddSalesConsumableAppConfig } from "./SalesPurchase/SalesConsumable/AddSalesConsumable/AddSalesConsumableAppConfig";
import { SalesB2CInfoAppConfig } from "./SalesPurchase/SalesB2CInfo/SalesB2CInfoAppConfig";
import { AddSalesB2CInfoAppConfig } from "./SalesPurchase/SalesB2CInfo/AddSalesB2CInfo/AddSalesB2CAppConfig";
import { SalesB2CAppConfig } from "./SalesPurchase/SalesB2C/SalesB2CAppConfig";
import { AddSalesB2CAppConfig } from "./SalesPurchase/SalesB2C/AddSalesB2C/AddSalesB2CAppConfig";
import { StockArticianIssueMetalAppConfig } from "./SalesPurchase/StockArticianIssueMetal/StockArticianIssueMetalAppConfig";
import { AddStockArticianIssueMetalAppConfig } from "./SalesPurchase/StockArticianIssueMetal/AddStockArticianIssueMetal/AddStockArticianIssueMetalAppConfig";
import { AddSendOfReproductionAppConfig } from "./SalesPurchase/SendOfReproduction/AddSendOfReproduction/AddSendOfReproductionAppConfig";
import { ViewSendOfReproductionAppConfig } from "./SalesPurchase/SendOfReproduction/ViewSendOfReproduction/ViewSendOfReproductionAppConfig";
import { StockArticianReturnMetalAppConfig } from "./SalesPurchase/StockArticianReturnMetal/StockArticianReturnMetalAppConfig";
import { AddStockArticianReturnMetalAppConfig } from "./SalesPurchase/StockArticianReturnMetal/AddStockArticianReturnMetal/AddStockArticianReturnMetalAppConfig";
import { StockJewelPurchaseArticianAppConfig } from "./SalesPurchase/StockJewelPurchaseArtician/StockJewelPurchaseArticianAppConfig";
import { AddStockJewelPurchaseArticianAppConfig } from "./SalesPurchase/StockJewelPurchaseArtician/AddStockJewelPurchaseArtician/AddStockJewelPurchaseArticianAppConfig";
import { GSTRTwoBVoucherRegisterAppConfig } from "./Reports/GSTRTwoBVoucherRegister/GSTRTwoBVoucherRegisterAppConfig";
import { GSTROneDocumentSummaryAppConfig } from "./Reports/GSTROneDocumentSummary/GSTROneDocumentSummaryAppConfig";
import { GSTROneVoucherRegisterAppConfig } from "./Reports/GSTROneVoucherRegister/GSTROneVoucherRegisterAppConfig";
import { HsnSacSummaryReportAppConfig } from "./Reports/HsnSacSummaryReport/HsnSacSummaryReportAppConfig";
import { GSTRTwoReconcileMainReportAppConfig } from "./Reports/GSTRTwoReconcileMainReport/GSTRTwoReconcileMainReportAppConfig";
import { GSTRTwoReconcileSubReportAppConfig } from "./Reports/GSTRTwoReconcileSubReport/GSTRTwoReconcileSubReportAppConfig";
import { BalanceSheetStockSummaryAppConfig } from "./Reports/BalanceSheetStockSummary/BalanceSheetStockSummaryAppConfig";
import { StockSummaryReportAppConfig } from "./Reports/StockSummaryReport/StockSummaryReportAppConfig";
import { UserComplainAppConfig } from "./MobileAppAdmin/ComplainBox/UserComplainAppConfig";
import { EditUserComplainAppConfig } from "./MobileAppAdmin/ComplainBox/EditUserComplain/EditUserComplainAppConfig";
import { auditTrailAppConfig } from "./Account/AuditTrail/AuditTrailAppConfig";
import { GSTRThreeVoucherRegisterAppConfig } from "./Reports/GSTRThreeVoucherRegister/GSTRThreeVoucherRegisterAppConfig";
import { TrendingAccountReportAppConfig } from "./Reports/TrandingAccountReport/TrandingAccountReportAppConfig";
import { GSTRThreeBReportAppConfig } from "./Reports/GSTRThreeBReport/GSTRThreeBReportAppConfig";
import { BalanceSheetReportAppConfig } from "./Reports/BalanceSheetReport/BalanceSheetReportAppConfig";
import { TcsReportPartyWiseAndSectionWiseAppConfig } from "./Reports/TcsReportPartyWiseAndSectionWise/TcsReportPartyWiseAndSectionWiseAppConfig";
import { TdsReportPartyWiseAndSectionWiseAppConfig } from "./Reports/TdsReportPartyWiseAndSectionWise/TdsReportPartyWiseAndSectionWiseAppConfig";
import { TrialBalanceReportAppConfig } from "./Reports/TrialBalanceReport/TrialBalanceReportAppConfig";
import { ProfitAndLossReportAppConfig } from "./Reports/ProfitAndLossReport/ProfitAndLossReportAppConfig";
import { StockValuationAppConfig } from "./Reports/StockValuation/StockValuationAppConfig";
import { ProcesslineAppConfig } from "./Masters/Processline/ProcesslineAppConfig";
import { AddProcesslineAppConfig } from "./Masters/Processline/Addprocessline/AddProcesslineAppConfig";
import { EditProcesslineAppConfig } from "./Masters/Processline/EditProcessline/EditProcesslineAppConfig";
import { TreeViewAppConfig } from "./Account/TreeView/TreeViewAppConfig";
import { GSTRateReportAppConfig } from "./Reports/GSTRateReport/GSTRateReportAppConfig";
import { CreatePlanAndLotAppConfig } from "./Planning/CreatePlanAndLot/CreatePlanAndLotAppConfig";
import { PlanningDashboardAppCofig } from "./Planning/PlanningDashboard/PlanningDashboardAppCofig";
import { PlanningLotsAppConfig } from "./Planning/PlanningLots/PlanningLotsAppConfig";
import { PlanningOrdersAppConfig } from "./Planning/PlanningOrders/PlanningOrdersAppConfig";
import { ProductionOrderDetailsAppConfig } from "./Planning/OrderDetails/ProductionOrderDetailsAppConfig";
import { VoucherInfoAppConfig } from "./Account/VoucherInfo/VoucherInfoAppConfig";
import { ItcZeroFourIssueReportAppConfig } from "./Reports/ItcZeroFourIssueReport/ItcZeroFourIssueReportAppConfig";
import { ItcZeroFourReceiveReportAppConfig } from "./Reports/ItcZeroFourReceiveReport/ItcZeroFourReceiveReportAppConfig";
import { EditBomDetailsAppConfig } from "./Planning/Components/EditBomDetails/EditBomDetailsAppConfig";
import { AcceptTransferAppConfig } from "./Production/ProductionMain/PopUps/AcceptTransfer/AcceptTransferAppConfig";
import { CRMAppConfig } from "./MobileAppAdmin/CRM/CRMAppConfig";
import { EditCRMAppConfig } from "./MobileAppAdmin/CRM/EditCRM/EditCRMAppConfig";
import { ReceiveBalanceStockAppConfig } from "./Production/ProductionMain/PopUps/ReceiveBalanceStock/ReceiveBalanceStockAppConfig";
import { ProductionHistoryAppConfig } from "./Account/ProductionHistory/ProductionHistoryAppConfig";
import { RecieveFromCastingAppConfig } from "./Production/ProductionMain/PopUps/RecieveFromCasting/RecieveFromCastingAppConfig";
import { WorkStationRateAppConfig } from "./Masters/WorkStationRateProf/WorkStationRateAppConfig";
import { ViewWorkStationRateAppConfig } from "./Masters/WorkStationRateProf/SubView/ViewWorkStationRateAppConfig";
import { LotDetailSearchAppConfig } from "./LotDetailSearch/LotDetailSearchAppConfig";
import { CustomerSummariseBalanceAppConfig } from "./Reports/CustomerSummariseBalance/CustomerSummariseBalanceAppConfig";
import { CustomizeLossReportAppConfig } from "./Reports/CustomizeLossReport/CustomizeLossReportAppConfig";
import { StockTakingAppConfig } from "./Production/ProductionMain/PopUps/StockTaking/StockTakingAppConfig";
import { IssueToWorkerReportAppConfig } from "./Reports/IssueToWorkerReport/IssueToWorkerReportAppConfig";
import { ReceiveFromWorkerReportAppConfig } from "./Reports/ReceiveFromWorkerReport/ReceiveFromWorkerReportAppConfig";
import { DailyProductionReportAppConfig } from "./Reports/DailyProductionReport/DailyProductionReportAppConfig";
import { FilingWIPReportAppConfig } from "./Reports/FilingWIPReport/FilingWIPReportAppConfig";
import { RejectionReportAppConfig } from "./Reports/RejectionReport/RejectionReportAppConfig";
import { DailyProductionReportTwoAppConfig } from "./Reports/DailyProductionReportTwo/DailyProductionReportTwoAppConfig";
import { OrderListReportAppConfig } from "./Reports/OrderListReport/OrderListReportAppConfig";
import { OrderStatusReportAppConfig } from "./Reports/OrderStatusReport/OrderStatusReportAppConfig";
import { LooseItemReportAppConfig } from "./Reports/LooseItemReport/LooseItemReportAppConfig";
import { EditSelectedBomDetailsAppConfig } from "./Planning/Components/EditSelectedBomDetails/EditSelectedBomDetailsAppConfig";
import { IssueForMeltingAppConfig } from "./Production/ProductionMain/PopUps/IssueForMelting/IssueForMetlingAppConfig";
import { ProductionHistorytwoAppConfig } from "./Production/ProductionMain/PopUps/ProductionHistory/ProductionHistorytwoAppConfig";
import { PrintPacketAppConfig } from "./Tagging/PrintPacket/PrintPacketAppConfig";
import { IssueReceiveReportAppConfig } from "./Reports/IssueReceiveReport/IssueReceiveReportAppConfig";
import { WorkstationWiseBalanceReportAppConfig } from "./Reports/WorkstationWiseBalanceReport/WorkstationWiseBalanceReportAppConfig";
import { SundryCreditPayableAppConfig } from "./Reports/SundryCreditPayable/SundryCreditPayableAppConfig";
import { SundryDebitReceivableAppConfing } from "./Reports/SundryDebitReceivable/SundryDebitReceivableAppConfig";
import { RcmLedgerReportAppConfig } from "./Reports/RCMLedgerReport/RcmLedgerReportAppConfig";
import { JobworkStockStatementSummaryAppConfig } from "./Reports/JobworkStockStatementSummary/JobworkStockStatementSummaryAppConfig";
import { VoucherEditMoreAppConfig } from "./Account/VoucherHistory/VoucherEditMore/VoucherEditMoreAppConfig";
import { SalesExportInfoAppConfig } from "./SalesPurchase/SalesExportInfo/SalesExportInfoAppConfig";
import { AddSalesExportInfoAppConfig } from "./SalesPurchase/SalesExportInfo/AddSalesExportInfo/AddSalesExportInfoAppConfig";
import { SalesExportAppConfig } from "./SalesPurchase/SalesExport/SalesExportAppConfig";
import { AddSalesExportAppConfig } from "./SalesPurchase/SalesExport/AddSalesExport/AddSalesExportAppConfig";
import { OpeningStockValuationAppConfig } from "./Reports/OpeningStockValuation/OpeningStockValuationAppConfig";
import { RateMatchReportAppConfig } from "./Reports/RateMatchReport/RateMatchReportAppConfig";
import { StockTakingFromWorkstationAppConfig } from "./Production/ProductionMain/PopUps/StockTakingFromWorkstation/StockTakingFromWorkstationAppConfig";
import { AddBagDustWeightAppConfig } from "./Production/ProductionMain/PopUps/AddBagDustWeight/AddBagDustWeightAppConfig";
import { BarcodeSummaryReportAppConfig } from "./Reports/BarcodeSummaryReport/BarcodeSummaryReportAppConfig";
import { purchase_PurchaseReturnReportAppConfig } from "./Reports/Purchase&PurchaseReturn/Purchase&PurchaseReturnAppConfig";
import { jobworkerWorkstationAppConfig } from "./Reports/JobworkerWorkstation/JobworkerWorkstationAppConfig";
import { SalesAndReturnListAppConfig } from "./Reports/SalesAndReturnList/SalesAndReturnListAppConfig";
import { MisGoldReportAppConfig } from "./Reports/MisGoldReport/MisGoldReportAppConfig";
import { MisStoneReportAppConfig } from "./Reports/MisStoneReport/MisStoneReportAppConfig";
import { MisClientsReportAppConfig } from "./Reports/MisClientsReport/MisClientsReportAppConfig";
import { MisVendorsReportAppConfig } from "./Reports/MisVendorsReport/MisVendorsReportAppConfig";
import { MisJobworkersReportAppConfig } from "./Reports/MisJobworkersReport/MisJobworkersReportAppConfig";
import { MisFinalReportAppConfig } from "./Reports/MisFinalReport/MisFinalReportAppConfig";
import { SalesOtherAppConfig } from "./SalesPurchase/SalesOther/SalesOtherAppConfig";
import { AddSalesOtherAppConfig } from "./SalesPurchase/SalesOther/AddSalesOther/AddSalesOtherAppConfig";
import { RateFixTempRateDetailsReportAppConfig } from "./Reports/RateFixTempRateDetailsReport/RateFixTempRateDetailsReportAppConfig";
import { URDPurchaseAppConfig } from "./SalesPurchase/URDPurchase/URDPurchaseAppConfig";
import { AddURDPurchaseAppConfig } from "./SalesPurchase/URDPurchase/AddURDPurchase/AddURDPurchaseAppConfig";
import { BillWiseVoucherUtilizeReportAppConfig } from "./Reports/BillWiseVoucherUtilizeReport/BillWiseVoucherUtilizeReportAppConfig";
import { ClosingValuationPartTwoAppConfig } from "./Reports/ClosingValuationPartTwo/ClosingValuationPartTwoAppConfig";
import { VoucherWiseSummaryReportAppConfig } from "./Reports/VoucherWiseSummaryReport/VoucherWiseSummaryReportAppConfig";
import { OrderSummaryReportAppConfig } from "./Reports/OrderSummaryReport/OrderSummaryReportAppConfig";
import { CategoryWiseSalesReportAppConfig } from "./Reports/CategoryWiseSalesReport/CategoryWiseSalesReportAppConfig";
import { MainCategoryWiseSalesReportAppConfig } from "./Reports/MainCategoryWiseSalesReport/MainCategoryWiseSalesReportAppConfig";

export const appsConfigs = [
  MainCategoryWiseSalesReportAppConfig,
  CategoryWiseSalesReportAppConfig,
  OrderSummaryReportAppConfig,
  VoucherWiseSummaryReportAppConfig,
  ClosingValuationPartTwoAppConfig,
  BillWiseVoucherUtilizeReportAppConfig,
  AddURDPurchaseAppConfig,
  URDPurchaseAppConfig,
  RateFixTempRateDetailsReportAppConfig,
  AddSalesOtherAppConfig,
  SalesOtherAppConfig,
  MisFinalReportAppConfig,
  MisJobworkersReportAppConfig,
  MisVendorsReportAppConfig,
  MisClientsReportAppConfig,
  MisStoneReportAppConfig,
  MisGoldReportAppConfig,
  SalesAndReturnListAppConfig,
  jobworkerWorkstationAppConfig,
  purchase_PurchaseReturnReportAppConfig,
  BarcodeSummaryReportAppConfig,
  StockTakingFromWorkstationAppConfig,
  AddBagDustWeightAppConfig,
  RateMatchReportAppConfig,
  VoucherEditMoreAppConfig,
  AddSalesExportAppConfig,
  SalesExportAppConfig,
  AddSalesExportInfoAppConfig,
  SalesExportInfoAppConfig,
  VoucherEditMoreAppConfig,
  OpeningStockValuationAppConfig,
  RcmLedgerReportAppConfig,
  JobworkStockStatementSummaryAppConfig,
  SundryCreditPayableAppConfig,
  SundryDebitReceivableAppConfing,
  WorkstationWiseBalanceReportAppConfig,
  IssueReceiveReportAppConfig,
  PrintPacketAppConfig,
  ProductionHistorytwoAppConfig,
  IssueForMeltingAppConfig,
  EditSelectedBomDetailsAppConfig,
  LooseItemReportAppConfig,
  OrderStatusReportAppConfig,
  OrderListReportAppConfig,
  DailyProductionReportTwoAppConfig,
  RejectionReportAppConfig,
  FilingWIPReportAppConfig,
  DailyProductionReportAppConfig,
  ReceiveFromWorkerReportAppConfig,
  IssueToWorkerReportAppConfig,
  StockTakingAppConfig,
  CustomizeLossReportAppConfig,
  CustomerSummariseBalanceAppConfig,
  LotDetailSearchAppConfig,
  ViewWorkStationRateAppConfig,
  WorkStationRateAppConfig,
  RecieveFromCastingAppConfig,
  ProductionHistoryAppConfig,
  ReceiveBalanceStockAppConfig,
  EditCRMAppConfig,
  CRMAppConfig,
  AcceptTransferAppConfig,
  EditBomDetailsAppConfig,
  ItcZeroFourReceiveReportAppConfig,
  ItcZeroFourIssueReportAppConfig,
  VoucherInfoAppConfig,
  ProductionOrderDetailsAppConfig,
  TreeViewAppConfig,
  GSTRateReportAppConfig,
  PlanningOrdersAppConfig,
  PlanningLotsAppConfig,
  CreatePlanAndLotAppConfig,
  PlanningDashboardAppCofig,
  StockValuationAppConfig,
  auditTrailAppConfig,
  ProfitAndLossReportAppConfig,
  TrialBalanceReportAppConfig,
  TdsReportPartyWiseAndSectionWiseAppConfig,
  TcsReportPartyWiseAndSectionWiseAppConfig,
  BalanceSheetReportAppConfig,
  GSTRThreeBReportAppConfig,
  TrendingAccountReportAppConfig,
  GSTRThreeVoucherRegisterAppConfig,
  EditProcesslineAppConfig,
  AddProcesslineAppConfig,
  ProcesslineAppConfig,
  EditUserComplainAppConfig,
  UserComplainAppConfig,
  StockSummaryReportAppConfig,
  BalanceSheetStockSummaryAppConfig,
  GSTRTwoReconcileSubReportAppConfig,
  GSTRTwoReconcileMainReportAppConfig,
  HsnSacSummaryReportAppConfig,
  GSTROneVoucherRegisterAppConfig,
  GSTROneDocumentSummaryAppConfig,
  GSTRTwoBVoucherRegisterAppConfig,
  GSTROneSummaryReportAppConfig,
  FineGoldReportAppConfig,
  ViewSendOfReproductionAppConfig,
  AddSendOfReproductionAppConfig,
  SendOfReproductionAppConfig,
  AddSalesB2CAppConfig,
  SalesB2CAppConfig,
  AddSalesB2CInfoAppConfig,
  SalesB2CInfoAppConfig,
  AddSalesConsumableAppConfig,
  SalesConsumableAppConfig,
  AddStockJewelPurchaseArticianAppConfig,
  StockJewelPurchaseArticianAppConfig,
  AddStockArticianReturnMetalAppConfig,
  StockArticianReturnMetalAppConfig,
  AddStockArticianIssueMetalAppConfig,
  StockArticianIssueMetalAppConfig,
  AddMeltingReceiveAppConfig,
  AddMeltingIssueAppConfig,
  MeltingReceiveAppConfig,
  MeltingIssueAppConfig,
  StoneAssortmentAppConfing,
  IssueForAlloyingAppConfig,
  AddAReferenceNumber,
  BreakATreeAppConfig,
  MakeATreeAppConfig,
  MergeAppConfig,
  SplitAppConfig,
  MakeaBunchAppConfig,
  ReceiveFromWorkerAppConfig,
  RemoveFromLotAppConfig,
  AddToLotAppConfig,
  IssueToWorkerAppConfig,
  ProductionMainAppConfig,
  AnniversaryListAppConfig,
  DistributorDetailViewAppConfig,
  DeletedVoucherAppConfig,
  AddDisCatalogueAppConfig,
  AddNewLeadAppConfig,
  DistributerCatalogueAppConfig,
  OtherLeadAppConfig,
  AddOrderAppConfig,
  VariantAttributeAppConfig,
  HallmarkIssuedListkAppConfig,
  ViewEditEngImgAppConfig,
  TagPrinterAppConfig,
  TagSystemAppConfig,
  CountryAppConfig,
  CityAppConfig,
  SiteSettingsAppConfig,
  MoldRejectionAppConfig,
  MoldRepairingAppConfig,
  ReceiveFromMoldAppConfig,
  IsueeToMoldAppConfig,
  CromeRejectionAppConfig,
  CromeRepairingAppconfig,
  ReceiveFromCromAppConfig,
  IsueeToCromeAppConfig,
  SilverRejectionAppConfig,
  SilverRepairingAppConfig,
  ReceiveFromSilverAppConfig,
  IsueeSilverCastingAppConfig,
  MasterRejectionReceivedAppConfig,
  MatserRepairingAppConfig,
  ReceiveFromWorkerMasterAppConfig,
  IsueeMasterFinishingAppConfig,
  CAMRejectionReceivedAppConfig,
  CAMReparingAppconfig,
  CAMReceiveFromWorkerAppConfig,
  CAMIssueToWorkerAppConfig,
  CreateNewCADAppConfig,
  CADRepairingAppConfig,
  CADRejectionReceivedAppConfig,
  CreateNewCollectionAppConfig,
  CreateNewDesignJobAppConfig,
  DesignerRoleAppConfig,
  DesignerLocationAppConfig,
  DesignUserAppConfig,
  BaggingAppConfig,
  TypeOfSettingAppConfig,
  SettingMethodAppConfig,
  ItemTypeAppConfig,
  EditCollectionAppConfig,
  StockGroupAppConfig,
  StockNameAppConfig,
  SystemUserAppConfig,
  EmployeeAppConfig,
  AddEmployeeAppConfig,
  AddClientAppConfig,
  EditClientAppConfig,
  ClientListAppConfig,
  ProductCategoryAppConfig,
  HSNMasterAppConfig,
  DepartmentAppConfig,
  ProcessAppConfig,
  ViewSalesRateProfileAppConfig,
  SalesRateProfileAppConfig,
  AddVendorAppConfig,
  EditVendorAppCofig,
  VendorAppConfig,
  BTOCClientAppConfig,
  UserRoleAppConfig,
  AddJobWorkerAppConfig,
  EditJobWorkerAppConfig,
  JobWorkerAppConfig,
  AddSalesManAppConfig,
  EditSalesManAppConfig,
  SalesMenAppConfig,
  AddWorkStationAppConfig,
  EditWorkStationAppConfig,
  WorkStationAppConfig,
  AddRateFixClientAppConfig,
  RateFixClientAppConfig,
  RateMatchAppConfig,
  AddRateFixVendorAppConfig,
  RateFixVendorAppConfig,
  CreateAccountAppConfig,
  VoucherEntryAppConfig,
  HallmarkStationAppConfig,
  AddMetalPurchaseAppConfig,
  MetalPurchaseAppConfig,
  AddJewellaryPurchaseAppConfig,
  JewelleryPurchaseAppConfig,
  AddJewelPurchaseArticianAppConfig,
  JewelPurchaseArticianAppConfig,
  AddExportMetalPurchaseAppConfig,
  ExportMetalPurchaseAppConfig,
  AddConsumablePurchaseAppConfig,
  ConsumablePurchaseAppConfig,
  OpningTriBlanAppConfing,
  AddRawMaterialPurchaseAppConfig,
  RawMaterialPurchaseAppConfig,
  AddMetalPurchaseReturnAppConfig,
  MetalPurchaseReturnAppConfig,
  CreatePacketAppConfig,
  PackingSlipAppConfig,
  ReGenBarcodeAppConfig,
  TagMakingLotAppConfig,
  EditPackingSlipAppConfig,
  UnreservedAppConfig,
  TagMakingMixAppConfig,
  AddConsumablePurcReturnAppConfig,
  ConsumablePurchaseReturnAppConfig,
  AddRawMatPurcReturnAppConfig,
  RawMaterialPurchaseReturnAppConfig,
  AddJewellaryPurchaseReturnAppConfig,
  JewelleryPurchaseReturnAppConfig,
  AddJewelPurchaseArticianReturnAppConfig,
  JewelPurchaseArticianReturnAppConfig,
  AddJobworkMetalReceiveIRAppConfig,
  JobworkMetalReceiveIRAppConfig,
  AddJobworkMetalReturnIRAppConfig,
  JobworkMetalReturnIRAppConfig,
  AddArticianReturnIRAppConfig,
  ArticianReturnIRAppConfig,
  AddArticianIssueIRAppConfig,
  ArticianIssueIRAppConfig,
  CollectionVariantWiseAppConfig,
  CollectionSizeWiseAppConfig,
  EditDesignAppConfig,
  EditPacketAppConfig,
  AddSalesDomesticAppConfig,
  SalesDomesticAppConfig,
  AddSalesDomesticInfoAppConfig,
  SalesDomesticInfoAppConfig,
  AddSalesJobworkAppConfig,
  SalesJobworkAppConfig,
  AddSalesJobworkInfoAppConfig,
  SalesJobworkInfoAppConfig,
  AddRepairingRecfromCustAppConfig,
  RepairingRecfromCustAppConfig,
  AddRepairedJewelReturnCustAppConfig,
  RepairedJewelReturnCustAppConfig,
  LedgerRateAppConfig,
  GoldRateAppConfig,
  MetalLedStmtAppConfig,
  BarcodeGeneratorReportAppConfig,
  BodarCodeGenerationAccAndLossAppConfig,
  PackingListReportAppConfig,
  PackingListWithHUIDAppConfig,
  StockTypeWiseDepartmentwiseStoRAppConfig,
  PartyWiseMetalAccountBalanceAppConfing,
  MisFactoryStockAppconfig,
  RateFixandPurcSlaesListAppConfig,
  HallmarkwiseInnAndOutAppConfig,
  DepartmentTransferSummaryAppConfig,
  AccountLedStmtAppConfig,
  JobworkStockStmtAppConfig,
  AddRepairedIssToJobWorkerAppConfig,
  RepairedIssToJobWorAppConfig,
  AddRepairedJewelReturnJobWorkerApConfig,
  RepairedJewelReturnJobWorkerAppConfig,
  AddSalesReturnJobworkAppConfig,
  SalesReturnJobworkAppConfig,
  AddSalesReturnDomesticAppConfig,
  SalesReturnDomesticAppConfig,
  AcceptHmTransferAppConfig,
  IssueToHallmarkAppConfig,
  FinishPurityAppConfig,
  StateAppConfig,
  VoucherEditViewAppConfig,
  VoucherHistoryAppConfig,
  WeightToleranceAppConfig,
  RecFromHallmarkAppConfig,
  ViewTaggingRateProfileAppConfig,
  TaggingRateProfileAppConfig,
  ViewJwVendRateProfAppConfig,
  JobwokerVendorRateProfAppConfig,
  AddToolsConsumptionAppConfig,
  ToolsConsumptionAppConfig,
  SalesEstimateAppConfig,
  LedgerReportAppConfig,
  LedgerRepAccAppConfig,
  AcceptStockTransAppConfig,
  StockAppConfig,
  HallmarkChargesAppConfig,
  TempPrintAppConfig,
  CreateCompanyAppConfig,
  RetailerMasterAppConfig,
  AddUserAppConfig,
  DeletedListAppConfig,
  BirthdayListAppConfig,
  DeletedSalesmanAppConfig,
  AddSalesmanMobAppConfig,
  SalesmanMobAppConfig,
  CreateDistributorAppConfig,
  DistributorMasterAppConfig,
  TeaserDesignAppConfig,
  UpdateBrandingsAppConfig,
  BrandingAppConfig,
  SalesmanAssociationAppConfig,
  CompanyAssociationAppConfig,
  PushNotificationAppConfig,
  AddCatalogueAppConfig,
  MyCatalogueAppConfig,
  AddNewsUpdatesAppConfig,
  NewsAndUpdateAppConfig,
  AddExhibitionAppConfig,
  ExhibitionMasterAppConfig,
  ProsOrderDetailAppConfig,
  LoginSubDetailsAppConfig,
  OrderDetailsAppConfig,
  ProspectiveOrderAppConfig,
  LoginDetailsAppConfig,
  AddPrimaryAllocationAppConfig,
  PrimaryAllocationAppConfig,
  OrdersAppConfig,
  CategoriesAppConfig,
  CmsAppConfig,
  UserMasterAppConfig,
  EditVoucherAppConfig,
  OtherAccVoucherAppConfig,
  VoucherListAppConfig,
  EditCADAppConfig,
  ReportAppConfig,
  HallmarkAppConfig, //put on last from here
  MainDesignAppConfig,
  BlankPageAppConfig,
  AccountBlankPageAppConfig,
  SalesBlankPageAppConfig,
  BlankMobilePageAppConfig,
  ReportBlankPageAppConfig,
  // MainDashboardAppConfig
  // ProjectDashboardAppConfig,
  // MailAppConfig,
  // TodoAppConfig,
  // FileManagerAppConfig,
  // ContactsAppConfig,
  // CalendarAppConfig,
  // ChatAppConfig,
  // ECommerceAppConfig,
  // ScrumboardAppConfig,
  // AcademyAppConfig,
  // NotesAppConfig
];
