class accountNavConfig {
  static accountNavConfigArr() {
    const isSuperAdmin = localStorage.getItem("isSuperAdmin");
    const roleOfUser = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null;
    const module = roleOfUser ? roleOfUser["Account"] : null;
    const keys = module ? Object.keys(module) : [];

    const dataArr = [
      {
        id: "createaccount",
        title: "Accounts",
        type: "item",
        url: "/dashboard/accounts/createaccount",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Accounts"),
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "vouchers",
        title: "Vouchers",
        type: "item",
        url: "/dashboard/accounts/voucherlist",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Vouchers"),
      },
      {
        type: "divider",
        id: "divider-2",
      },
      {
        id: "othervouchers",
        title: "Other Accounting Vouchers",
        type: "item",
        url: "/dashboard/accounts/othervoucherlist",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Other Vouchers"),
      },
      {
        type: "divider",
        id: "divider-3",
      },
      {
        id: "voucherentry",
        title: "Voucher Entry",
        type: "item",
        url: "/dashboard/accounts/voucherentry",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Vouchers Entry"),
      },
      {
        type: "divider",
        id: "divider-4",
      },
      {
        id: "voucherhistory",
        title: "Voucher History",
        type: "item",
        url: "/dashboard/accounts/voucherhistory",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Vouchers History"),
      },
      {
        type: "divider",
        id: "divider-5",
      },
      {
        id: "voucherdeletehistory",
        title: "Deleted Voucher",
        type: "item",
        url: "/dashboard/accounts/deletedvoucher",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Deleted Voucher"),
      },
      {
        type: "divider",
        id: "divider-6",
      },
      {
        id: "ledgerReportGrp",
        title: "Ledger Report Group Wise",
        type: "item",
        url: "/dashboard/accounts/ledgerreportgroup",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Ledger Report Group Wise"),
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "ledgerReportAcc",
        title: "Ledger Report Ledger Wise",
        type: "item",
        url: "/dashboard/accounts/ledgerreportledger",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Ledger Report Ledger Wise"),
      },
      {
        type: "divider",
        id: "divider-8",
      },
      {
        id: "audittrail",
        title: "Audit Trail",
        type: "item",
        url: "/dashboard/accounts/audittrail",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Audit Trial"),
      },
      {
        type: "divider",
        id: "divider-9",
      },
      {
        id: "treeview",
        title: "Group Tree",
        type: "item",
        url: "/dashboard/accounts/treeview",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Group Tree"),
      },
      {
        type: "divider",
        id: "divider-10",
      },
      {
        id: "voucherinfo",
        title: "Voucher Info",
        type: "item",
        url: "/dashboard/accounts/voucherinfo",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Voucher Info"),
      },
      {
        type: "divider",
        id: "divider-11",
      },
      {
        id: "productionhistory",
        title: "Production History",
        type: "item",
        url: "/dashboard/accounts/productionhistory",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Production History"),
      },
      {
        type: "divider",
        id: "divider-12",
      },
    ];
    return dataArr;
  }
}

export default accountNavConfig;
