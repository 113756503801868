import React from "react";
export const AddToLotAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/production/addtolot",
      component: React.lazy(() => import("./AddToLot")),
    },
  ],
};
