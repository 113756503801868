import React from 'react';
import VKjLoader from './VKJLoader.gif';

const LoaderPopup = () => {
    return (
        <div className="custom-popup-main-dv">
        <img
          className="spin-logo"
          src={VKjLoader}
          style={{ alignSelf: "center", width: "100px" }}
          alt="logo"
        />
      </div>
    )
}

export default LoaderPopup;