import React from "react";

export const RemoveFromLotAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/production/removefromlot",
      component: React.lazy(() => import("./RemoveFromLot")),
    },
  ],
};
