import React from 'react';

export const AddDisCatalogueAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : "/dashboard/mobappadmin/distributercatalogue/addDiscatalogue",
            component: React.lazy(() => import('./AddDisCatalogue'))
        },
        {
            path     : "/dashboard/mobappadmin/orders/addDiscatalogue",
            component: React.lazy(() => import('./AddDisCatalogue'))
        }
    ]
};


