import * as Actions from "app/store/actions";
import * as authActions from "app/auth/store/actions";
import jwtService from "app/services/jwtService";

const handleError = (error, dispatchMethod, params) => {
  if (!error.response) {
    dispatchMethod(
      Actions.showMessage({ message: error.message, variant: "error" })
    );
  } else if (error.response.status === 400) {
    let msg = error.response.data.hasOwnProperty("error")
      ? Array.isArray(error.response.data.error)
        ? error.response.data.error[0].message
        : error.response.data.error.message.message
        ? error.response.data.error.message.message
        : error.response.data.error.message
        ? error.response.data.error.message
        : error.response.data.message
        ? error.response.message.message
        : error.response.message.message
      : error.response.data.message;
    dispatchMethod(Actions.showMessage({ message: msg, variant: "error" }));
  } else if (error.response.status === 401) {
    let msg = error.response.data.hasOwnProperty("error")
      ? Array.isArray(error.response.data.error)
        ? error.response.data.error[0].message
        : error.response.data.error.message
      : error.response.data.message;

    dispatchMethod(
      Actions.showMessage({
        message: msg, // Array.isArray(error.response.data.error) ?  error.response.data.error[0].message :  error.response.data.error.message
        variant: "error",
      })
    );
    dispatchMethod(authActions.logoutUser("unauthorized", params));
  } else if (error.response.status === 404) {
    dispatchMethod(
      Actions.showMessage({ message: "Api Not Found", variant: "error" })
    );
  } else if (error.response.status === 409) {
    if (error.response.data.error) {
      let msg = error.response.data.hasOwnProperty("error")
        ? Array.isArray(error.response.data.error)
          ? error.response.data.error[0].message
          : error.response.data.error.message
          ? error.response.data.error.message.message ||
            error.response.data.error.message
          : "Blank Message"
        : error.response.data.error?.message || "Blank Message";

      dispatchMethod(
        Actions.showMessage({
          message: msg, // Array.isArray(error.response.data.error) ?  error.response.data.error[0].message :  error.response.data.error.message
          variant: "error",
        })
      );
    } else {
      // use for Sales Invoice Info (Jobwork) tabs add emty to not show error msg

      let msg = error.response.data.hasOwnProperty("error")
        ? Array.isArray(error.response.data)
          ? error.response.data.message
          : error.response.data.message
          ? error.response.data.message.message || error.response.data.message
          : "Blank Message"
        : error.response.data?.message || "Blank Message";

      dispatchMethod(
        Actions.showMessage({
          message: msg,
          variant: "error",
        })
      );
    }
  } else if (error.response.status === 422) {
    // dispatchMethod(
    //     Actions.showMessage({ message: error.response.data.message })
    // );
    let msg = error.response.data.hasOwnProperty("error")
      ? Array.isArray(error.response.data.error)
        ? error.response.data.error[0].message
        : error.response.data.error.message
      : error.response.data.message;

    dispatchMethod(
      Actions.showMessage({
        message: msg, // Array.isArray(error.response.data.error) ?  error.response.data.error[0].message :  error.response.data.error.message
        variant: "error",
      })
    );
  } else if (error.response.status === 440) {
    //token expired
    // dispatchMethod(Actions.showMessage({ message: "Session Refreshed" }));
    jwtService.refreshtokenApi(dispatchMethod);
  } else if (error.response.status === 500) {
    let msg = error.response.data.hasOwnProperty("error")
      ? Array.isArray(error.response.data.error)
        ? error.response.data.error[0].message
        : error.response.data.error.message
      : error.response.data.message;
    dispatchMethod(
      Actions.showMessage({
        message: msg, // Array.isArray(error.response.data.error) ?  error.response.data.error[0].message :  error.response.data.error.message
        variant: "error",
      })
    );
  } else {
    let msg = error.response.data.hasOwnProperty("error")
      ? Array.isArray(error.response.data.error)
        ? error.response.data.error[0].message
        : error.response.data.error.message
      : error.response.data.message;
    dispatchMethod(
      Actions.showMessage({
        message: msg, // Array.isArray(error.response.data.error) ?  error.response.data.error[0].message :  error.response.data.error.message
        variant: "error",
      })
    );
  }
};

export default handleError;
