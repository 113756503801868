// import React from 'react';
import ReportBlankPage from './ReportBlankPage';

export const ReportBlankPageAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/dashboard/reports',
            component: ReportBlankPage
        }
    ]
};
