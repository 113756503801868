import React from "react";
export const AddURDPurchaseAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/dashboard/sales/urdpurchase/addurdpurchase",
      component: React.lazy(() => import("./AddURDPurchase")),
    },
  ],
};
