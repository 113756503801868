import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'app/store/actions';
// import { FuseLayouts } from '@fuse';
import _ from '@lodash';
import AppContext from 'app/AppContext';
import * as authActions from "app/auth/store/actions";
import { logoutUser } from "app/auth/store/actions";
import Layout2 from 'app/fuse-layouts/layout2/Layout2';
import Layout1 from 'app/fuse-layouts/layout1/Layout1';
import { useIdleTimer } from "react-idle-timer";
import { env } from 'process';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& code:not([class*="language-"])': {
            color: theme.palette.secondary.dark,
            backgroundColor: '#F5F5F5',
            padding: '2px 3px',
            borderRadius: 2,
            lineHeight: 1.7
        },
        '& table.simple tbody tr td': {
            borderColor: theme.palette.divider
        },
        '& table.simple thead tr th': {
            borderColor: theme.palette.divider
        },
        '& a:not([role=button])': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& [class^="border-"]': {
            borderColor: theme.palette.divider
        },
        '& [class*="border-"]': {
            borderColor: theme.palette.divider
        }
    }
}));

function FuseLayout(props) {


    const dispatch = useDispatch();
    const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults);
    // console.log(defaultSettings)
    const settings = useSelector(({ fuse }) => fuse.settings.current);

    const classes = useStyles(props);
    const appContext = useContext(AppContext);
    const { routes } = appContext;
    const { setSelectedDepartment } = useContext(AppContext);

    useEffect(() => {
        const handleInvalidToken = e => {
            // console.log("handleInvalidToken ===============", e)

            if (e.key === 'authToken' && e.oldValue && !e.newValue) {
                // Your logout logic here
                // console.log(e)
                // dispatch(authActions.logoutUser("tab_logout", null));
                // not needed here on logout user or idle time session is expired we just need to 
                // logout user locally and send to login page, no need to call logs api, process is same, we can reload page 
                window.location.reload();
                // logoutAction(history);
            } else if (e.key === 'selDeptNm') {//for changing department in other tab
                // if we go through both storage condition, it will be called both time so one will be undefined thats why checked only one and got other from storage
                // checked which will be changed after, so we have latest department id in storage
                // deptNm = e.newValue;
                // console.log("deptId", e.storageArea)
                // console.log("e.newValue", e.newValue)
                // console.log("deptNm", deptNm)
                let allDept = JSON.parse(localStorage.getItem("allDepartments"));
                // console.log("allDept-------", allDept)
                let index = allDept.findIndex(item => item.id.toString() === e.storageArea.SelectedDepartment.toString())
                let deptIDvalue = "";
                // console.log("index----", index)
                if (index > -1) {
                    deptIDvalue = `SL${index}-${e.storageArea.SelectedDepartment}`
                } else {
                    deptIDvalue = e.storageArea.SelectedDepartment
                }
                setSelectedDepartment({
                    value: deptIDvalue,
                    label: e.newValue,
                });
                // setSelectedDepartment({
                //     value: e.storageArea.SelectedDepartment,
                //     label: e.newValue,
                // });
            }

        }
        window.addEventListener('storage', handleInvalidToken, { passive: false })
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
        //eslint-disable-next-line
    }, [logoutUser]);

    useEffect(() => {
        function routeSettingsCheck() {
            const matched = matchRoutes(routes, props.location.pathname)[0];
            // console.log("mathed ---------", matched)
            if (matched && matched.route.settings) {
                // console.log("if--------------")
                const routeSettings = _.merge({}, defaultSettings, matched.route.settings);
                if (!_.isEqual(settings, routeSettings)) {
                    // console.log("if setsettings ----")

                    dispatch(Actions.setSettings(_.merge({}, routeSettings)));
                } else {
                    // console.log("else setsettings ----")
                    // dispatch(Actions.setSettings(_.merge({}, settings)));

                }
            }
            else {
                if (!_.isEqual(settings, defaultSettings)) {
                    dispatch(Actions.resetSettings());
                }
            }
        }

        routeSettingsCheck();
    }, [defaultSettings, dispatch, props.location.pathname, routes, settings]);
    let path = props.location.pathname.split("/")
    // console.warn('path:: ',path);
    // console.log(FuseLayouts[settings.layout.style])

    var Layout;
    if (path[2] === "sales" || path[2] === "hallmarks" || path[2] === "hallmark" || path[2] === "accounts") {
        Layout = Layout2 //FuseLayouts[settings.layout.style];
    } else {
        Layout = Layout1//FuseLayouts[settings.layout.style];
    }

    // const onPrompt = () => {
    //     // Fire a Modal Prompt
    //     console.log("on prompt");
    //   };

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        console.log("onIdle");
        dispatch(authActions.logoutUser("idle"));
    };

    //   const onActive = (event) => {
    //     // Close Modal Prompt
    //     console.log("onActive>");

    //     // Do some active action
    //   };

    //   const onAction = (event) => {
    //     console.log("onAction");
    //     // Do something when a user triggers a watched event
    //   };
    // useIdleTimer({ onIdle, onActive, onAction, timeout: 10000 });
    useIdleTimer({
        // onPrompt,
        onIdle,
        // onActive,
        // onAction,
        timeout: process.env.REACT_APP_TIME ? process.env.REACT_APP_TIME : 600000,//10 minutes
        promptTimeout: 0,
        events: [
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "visibilitychange"
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: true,
        syncTimers: 0
    });


    return (
        <Layout classes={{ root: classes.root }} {...props} />
    );
}

export default withRouter(React.memo(FuseLayout));
