class reportNavConfig {
  static reportNavConfigArr() {
    const roleOfUser = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null;
    const module = roleOfUser ? roleOfUser["Report"] : null;
    const keys = module ? Object.keys(module) : [];

    const dataArr = [
      {
        id: "reportmain",
        title: "Factory Stock",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/factoryreport",
        isAccessible: keys.includes("Factory Stock"),
      },
      {
        type: "divider",
        id: "divider-0",
      },
      {
        id: "ledgerStatement",
        title: "Ledger Statement",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Metal Ledger Statement") ||
          keys.includes("Account Ledger Statement") ||
          keys.includes("Job Work Stock Statement") ||
          keys.includes("Job Work Stock Statement Summary") ||
          keys.includes("Bill Wise Voucher Utilize Report") ||
          keys.includes("Voucher Wise Summary Report"),
        children: [
          {
            id: "metalledger",
            title: "Metal Ledger",
            type: "item",
            url: "/dashboard/reports/metalledger",
            isAccessible: keys.includes("Metal Ledger Statement"),
          },
          {
            id: "accountledger",
            title: "Account Ledger",
            type: "item",
            url: "/dashboard/reports/accountledger",
            isAccessible: keys.includes("Account Ledger Statement"),
          },
          {
            id: "jobworkstmt",
            title: "Job Work Stock Statement",
            type: "item",
            url: "/dashboard/reports/jobworkstmt",
            isAccessible: keys.includes("Job Work Stock Statement"),
          },
          {
            id: "jobworkstmtsummary",
            title: "Job Work Stock Statement Summary",
            type: "item",
            url: "/dashboard/reports/jobworkstmtsummary",
            isAccessible: keys.includes("Job Work Stock Statement Summary"),
          },
          {
            id: "billwisevoucherutilizereport",
            title: "Bill Wise Voucher Utilize Report",
            type: "item",
            url: "/dashboard/reports/billwisevoucherutilizereport",
            isAccessible: keys.includes("Bill Wise Voucher Utilize Report"),
          },
          {
            id: "voucherwisesummaryreport",
            title: "Voucher Wise Summary Report",
            type: "item",
            url: "/dashboard/reports/voucherwisesummaryreport",
            isAccessible: keys.includes("Voucher Wise Summary Report"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "metalEdger",
        title: "Metal Ledger Reports ",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Barcode Generation Report") ||
          keys.includes("Barcode Summary Report") ||
          keys.includes("Barcode Generation Acc And Loss") ||
          keys.includes("Packing List Report") ||
          keys.includes("Packing List With HUID"),
        children: [
          {
            id: "barcodeGenerationReport",
            title: "Barcode Generation Report ",
            type: "item",
            url: "/dashboard/reports/barcodeGeneratorReport",
            isAccessible: keys.includes("Barcode Generation Report"),
          },
          {
            id: "barcodesummaryReport",
            title: "Barcode Summary Report ",
            type: "item",
            url: "/dashboard/reports/barcodesummaryReport",
            isAccessible: keys.includes("Barcode Summary Report"),
          },
          {
            id: "barcodeGenerationAccAndLoss",
            title: "Barcode Generation Acc and Loss ",
            type: "item",
            url: "/dashboard/reports/bodarCodeGenerationAccAndLoss",
            isAccessible: keys.includes("Barcode Generation Acc And Loss"),
          },
          {
            id: "packinglist",
            title: "Packing List Report ",
            type: "item",
            url: "/dashboard/reports/packingListReport",
            isAccessible: keys.includes("Packing List Report"),
          },
          {
            id: "packinglistHuid",
            title: "Packing List Wise HUID ",
            type: "item",
            url: "/dashboard/reports/packingListWithHUID",
            isAccessible: keys.includes("Packing List With HUID"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-2",
      },
      {
        id: "department",
        title: "Department Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Department Transfer Summary"),
        children: [
          {
            id: "departmentTransfer",
            title: "Department Transfer Summary",
            type: "item",
            url: "/dashboard/reports/departmentTransferSummary",
            isAccessible: keys.includes("Department Transfer Summary"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-3",
      },
      {
        id: "Balance",
        title: "Balance Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Party Wise Metal Account Balance") ||
          keys.includes("Sundry Creditors") ||
          keys.includes("Sundry Debitors"),
        children: [
          {
            id: "metalAccBalance",
            title: "Partywise Balance Metal + Account Summary",
            type: "item",
            url: "/dashboard/reports/partyWiseMetalAccountBalance",
            isAccessible: keys.includes("Party Wise Metal Account Balance"),
          },
          {
            id: "sundrypayable",
            title: "Sundry Creditors (Payable)",
            type: "item",
            url: "/dashboard/reports/sundrypayable",
            isAccessible: keys.includes("Sundry Creditors"),
          },
          {
            id: "sundryreceivable",
            title: "Sundry Debitors (Receivable )",
            type: "item",
            url: "/dashboard/reports/sundryreceivable",
            isAccessible: keys.includes("Sundry Debitors"),
          },
          {
            // id: "hallmarkwiseInnAndOut",
            // title: "Hallmark wise In and Out and Loss",
            // type: "item",
            // url: "/dashboard/reports/HallmarkwiseInnAndOut",
            // isAccessible: keys.includes('ss'),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-4",
      },
      {
        id: "mis",
        title: "MIS Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Rate Fix and Purchase Sales List") ||
          keys.includes("Stock Type Wise Department Wise Stock Report") ||
          keys.includes("Mis Factory Stock Report") ||
          keys.includes("MIS Gold Report") ||
          keys.includes("MIS Stone Report") ||
          keys.includes("MIS Client Report") ||
          keys.includes("MIS Vendor Report") ||
          keys.includes("MIS Jobwork Report") ||
          keys.includes("MIS Final Report"),
        children: [
          {
            id: "metalAccBalance",
            title: "Rate Fix and purchase Sales List ",
            type: "item",
            url: "/dashboard/reports/rateFixandPurcSlaesList",
            isAccessible: keys.includes("Rate Fix and Purchase Sales List"),
          },
          {
            id: "departmentStockReport",
            title: "Stock Type Wise Department wise Stock Report",
            type: "item",
            url: "/dashboard/reports/StockTypeWiseDepartmentwiseStoR",
            isAccessible: keys.includes(
              "Stock Type Wise Department Wise Stock Report"
            ),
          },
          {
            id: "misFactory",
            title: "Mis Factory Stock Report",
            type: "item",
            url: "/dashboard/reports/MisFactoryStock",
            isAccessible: keys.includes("Mis Factory Stock Report"),
          },
          {
            id: "misGoldReport",
            title: "Mis Gold Report",
            type: "item",
            url: "/dashboard/reports/misgoldreport",
            isAccessible: keys.includes("MIS Gold Report"),
          },
          {
            id: "misStoneReport",
            title: "Mis Stone Report",
            type: "item",
            url: "/dashboard/reports/misstonereport",
            isAccessible: keys.includes("MIS Stone Report"),
          },
          {
            id: "misClientsReport",
            title: "Mis Clients Report",
            type: "item",
            url: "/dashboard/reports/misclientsreport",
            isAccessible: keys.includes("MIS Client Report"),
          },
          {
            id: "misVendorsReport",
            title: "Mis Vendors Report",
            type: "item",
            url: "/dashboard/reports/misvendorsreport",
            isAccessible: keys.includes("MIS Vendor Report"),
          },
          {
            id: "misJobworkersReport",
            title: "Mis Jobwork Report",
            type: "item",
            url: "/dashboard/reports/misjobworkersreport",
            isAccessible: keys.includes("MIS Jobwork Report"),
          },
          {
            id: "misFinalReport",
            title: "Mis Final Report",
            type: "item",
            url: "/dashboard/reports/misfinalreport",
            isAccessible: keys.includes("MIS Final Report"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-5",
      },
      {
        id: "finegold",
        title: "Fine Gold Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/finegoldreport",
        isAccessible: keys.includes("Fine Gold Report"),
      },
      {
        type: "divider",
        id: "divider-6",
      },
      {
        id: "gstronereport",
        title: "GSTR-1 Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("GSTR1 Summary report") ||
          keys.includes("GSTR1 Document summary report") ||
          keys.includes("GSTR1 Voucher register report") ||
          keys.includes("GSTR1 HSN/SAC Report"),
        children: [
          {
            id: "gstronereport",
            title: "GSTR-1 Summary Report",
            type: "item",
            url: "/dashboard/reports/gstronesummaryreport",
            isAccessible: keys.includes("GSTR1 Summary report"),
          },
          {
            id: "gstronedocumentsummary",
            title: "GSTR-1 Document Summary Report",
            type: "item",
            url: "/dashboard/reports/gstronedocumentsummaryreport",
            isAccessible: keys.includes("GSTR1 Document summary report"),
          },
          {
            id: "gstronevoucherregister",
            title: "GSTR-1 Voucher Register Report",
            type: "item",
            url: "/dashboard/reports/gstronevoucherregister",
            isAccessible: keys.includes("GSTR1 Voucher register report"),
          },
          {
            id: "gstronehsnandsac",
            title: "GSTR-1 HSN/SAC Report",
            type: "item",
            url: "/dashboard/reports/gstronehsnsacreport",
            isAccessible: keys.includes("GSTR1 HSN/SAC Report"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "gstrtworeport",
        title: "GSTR-2 Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("GSTR2B Voucher register report") ||
          keys.includes("RCM Ledger report"),
        children: [
          {
            id: "gstrtworeport",
            title: "GSTR-2 B Voucher Register Report",
            type: "item",
            url: "/dashboard/reports/gstrtwobvoucherregister",
            isAccessible: keys.includes("GSTR2B Voucher register report"),
          },
          {
            id: "rcmledgerreport",
            title: "RCM Ledger Report",
            type: "item",
            url: "/dashboard/reports/rcmledgerreports",
            isAccessible: keys.includes("RCM Ledger report"),
          },
          // {
          //   id: "gstrtworeconcilemainreport",
          //   title: "GSTR-2 Reconcile Main Report",
          //   type: "item",
          //   url: "/dashboard/reports/gstrtworeconcilemainreport",
          //   isAccessible: true,
          // },
          // {
          //   id: "gstrtworeconcilesubreport",
          //   title: "GSTR-2 Reconcile Sub Report",
          //   type: "item",
          //   url: "/dashboard/reports/gstrtworeconcilesubreport",
          //   isAccessible: true,
          // },
        ],
      },
      {
        type: "divider",
        id: "divider-8",
      },
      // {
      //   id: "balancesheetstocksummary",
      //   title: "Balance Sheet Stock Summary Report",
      //   type: "item",
      //   icon: "play_circle_outline",
      //   url: "/dashboard/reports/balancesheetstocksummaryreport",
      //   isAccessible: true,
      // },
      // {
      //   type: "divider",
      //   id: "divider-9",
      // },
      // {
      //   id: "stocksummaryreport",
      //   title: "Stock Summary Report",
      //   type: "item",
      //   icon: "play_circle_outline",
      //   url: "/dashboard/reports/stocksummaryreport",
      //   isAccessible: true,
      // },
      // {
      //   type: "divider",
      //   id: "divider-10",
      // },
      // {
      //   id: "gstrthreereport",
      //   title: "GSTR-3 Report",
      //   type: "collapse",
      //   icon: "play_circle_outline",
      //   isAccessible: true,
      //   children: [
      //     {
      //       id: "gstrthreereport",
      //       title: "GSTR-3 B Report",
      //       type: "item",
      //       url: "/dashboard/reports/gstrthreebreport",
      //       isAccessible: true,
      //     },
      //     {
      //       type: "divider",
      //       id: "divider-13",
      //     },
      //     {
      //       id: "gstrthreeregisterreport",
      //       title: "GSTR-3 B Voucher Register Report",
      //       type: "item",
      //       url: "/dashboard/reports/gstrthreevoucherregister",
      //       isAccessible: true,
      //     },
      //   ],
      // },
      // {
      //   type: "divider",
      //   id: "divider-11",
      // },
      {
        id: "gstrate",
        title: "GST Rate Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/gstratereport",
        isAccessible: keys.includes("GST Rate report"),
      },
      {
        type: "divider",
        id: "divider-20",
      },
      {
        id: "tdsreportpartywiseandsectionwise",
        title: "TDS Report ", //Partywise and Sectionwise
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/tdsreportpartywiseandsectionwise",
        isAccessible: keys.includes("TDS report"),
      },
      {
        type: "divider",
        id: "divider-16",
      },
      {
        id: "tradingaccountyreport",
        title: "Trading Account Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/tradingaccountreport",
        isAccessible: keys.includes("Trading Account report"),
      },
      {
        type: "divider",
        id: "divider-12",
      },
      {
        id: "balancesheetreport",
        title: "Balance Sheet Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/balancesheetreport",
        isAccessible: keys.includes("Balance sheet report"),
      },
      {
        type: "divider",
        id: "divider-14",
      },
      // {
      //   id: "tcsreportpartywiseandsectionwise",
      //   title: "Tcs Report Partywise and Sectionwise",
      //   type: "item",
      //   icon: "play_circle_outline",
      //   url: "/dashboard/reports/tcsreportpartywiseandsectionwise",
      //   isAccessible: true,
      // },
      // {
      //   type: "divider",
      //   id: "divider-15",
      // },
      {
        id: "trialbalance",
        title: "Trial Balance Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/trialbalancereport",
        isAccessible: keys.includes("Trial Balance report"),
      },
      {
        type: "divider",
        id: "divider-17",
      },
      {
        id: "profitandloss",
        title: "Profit and Loss Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/profitandlossreport",
        isAccessible: keys.includes("Profit Loss report"),
      },
      {
        type: "divider",
        id: "divider-18",
      },
      {
        id: "ratefixtempratedetailsr",
        title: "Rate Fix - Temp Rate Details Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/ratefixtempratedetailsrreport",
        isAccessible: keys.includes("Rate Fix Temp Rate Details Report"),
      },
      {
        type: "divider",
        id: "divider-34",
      },
      {
        id: "stockvaluation",
        title: "Stock Valuation Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Closing Stock valuation") ||
          keys.includes("Opening stock valuation") ||
          keys.includes("Closing Valuation Part-2"),
        children: [
          {
            id: "closingstockvaluationreport",
            title: "Closing Stock Valuation",
            type: "item",
            url: "/dashboard/reports/closingstockvaluation",
            isAccessible: keys.includes("Closing Stock valuation"),
          },
          {
            id: "openingstockvaluationreport",
            title: "Opening Stock Valuation",
            type: "item",
            url: "/dashboard/reports/openingstockvaluation",
            isAccessible: keys.includes("Opening stock valuation"),
          },
          {
            id: "closingvaluationparttwo",
            title: "Closing Valuation Part-2",
            type: "item",
            url: "/dashboard/reports/closingvaluationparttwo",
            isAccessible: keys.includes("Closing Valuation Part-2"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-19",
      },
      {
        id: "itcissue",
        title: "ITC 04 Issue",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/itczerofourissuereport",
        isAccessible: keys.includes("ITC 04 Issue"),
      },
      {
        type: "divider",
        id: "divider-21",
      },
      {
        id: "itcreceive",
        title: "ITC 04 Receive",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/itczerofourreceivereport",
        isAccessible: keys.includes("ITC 04 Receive"),
      },
      {
        type: "divider",
        id: "divider-22",
      },
      {
        id: "productionreport",
        title: "Production Report",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Customer Summarise Balance") ||
          keys.includes("Receive Balance stock") ||
          keys.includes("Issue To Worker Report") ||
          keys.includes("Receive From Worker Report") ||
          keys.includes("Daily Production Report Format - 1") ||
          keys.includes("Daily Production Report Format - 2") ||
          keys.includes("WIP Report") ||
          keys.includes("Rejection Report") ||
          keys.includes("Order List Report") ||
          keys.includes("Order Status Report") ||
          keys.includes("Loose Item Report") ||
          keys.includes("Workstation Wise Balance report"),
        children: [
          {
            id: "customersummarisebalance",
            title: "Customer Summarise Balance",
            type: "item",
            url: "/dashboard/reports/customersummarisebalance",
            isAccessible: keys.includes("Customer Summarise Balance"),
          },
          {
            type: "divider",
            id: "divider-24",
          },
          {
            id: "customizelossreport",
            title: "Receive Balance Stock",
            type: "item",
            url: "/dashboard/reports/customizelossreport",
            isAccessible: keys.includes("Receive Balance stock"),
          },
          {
            type: "divider",
            id: "divider-25",
          },
          {
            id: "issuetoworkerreport",
            title: "Issue To Worker Report",
            type: "item",
            url: "/dashboard/reports/issuetoworkerreport",
            isAccessible: keys.includes("Issue To Worker Report"),
          },
          {
            type: "divider",
            id: "divider-26",
          },
          {
            id: "receivefromworkerreport",
            title: "Receive From Worker Report",
            type: "item",
            url: "/dashboard/reports/receivefromworkerreport",
            isAccessible: keys.includes("Receive From Worker Report"),
          },
          {
            type: "divider",
            id: "divider-27",
          },
          {
            id: "dailyproductionreport",
            title: "Daily Production Report Formate - 1",
            type: "item",
            url: "/dashboard/reports/dailyproductionreport",
            isAccessible: keys.includes("Daily Production Report Format - 1"),
          },
          {
            type: "divider",
            id: "divider-28",
          },
          {
            id: "dailyproductionreportformatetwo",
            title: "Daily Production Report Formate - 2",
            type: "item",
            url: "/dashboard/reports/dailyproductionreportformatetwo",
            isAccessible: keys.includes("Daily Production Report Format - 2"),
          },
          {
            type: "divider",
            id: "divider-29",
          },
          {
            id: "filingwipreport",
            title: "WIP Report",
            type: "item",
            url: "/dashboard/reports/filingwipreport",
            isAccessible: keys.includes("WIP Report"),
          },
          {
            type: "divider",
            id: "divider-30",
          },
          {
            id: "rejectionreport",
            title: "Rejection Report",
            type: "item",
            url: "/dashboard/reports/rejectionreport",
            isAccessible: keys.includes("Rejection Report"),
          },
          {
            type: "divider",
            id: "divider-31",
          },
          {
            id: "orderlistreport",
            title: "Order List Report",
            type: "item",
            url: "/dashboard/reports/orderlistreport",
            isAccessible: keys.includes("Order List Report"),
          },
          {
            type: "divider",
            id: "divider-32",
          },
          {
            id: "orderstatusreport",
            title: "Order Status Report",
            type: "item",
            url: "/dashboard/reports/orderstatusreport",
            isAccessible: keys.includes("Order Status Report"),
          },
          {
            type: "divider",
            id: "divider-33",
          },
          {
            id: "looseitemreport",
            title: "Loose Item Report",
            type: "item",
            url: "/dashboard/reports/looseitemreport",
            isAccessible: keys.includes("Loose Item Report"),
          },
          {
            type: "divider",
            id: "divider-33",
          },
          {
            id: "workstationwisebalancereport",
            title: "Workstation Wise Balance Report",
            type: "item",
            url: "/dashboard/reports/workstationwisebalancereport",
            isAccessible: keys.includes("Workstation Wise Balance report"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-23",
      },
      {
        id: "issuereceive",
        title: "Issue Receive Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/issuereceivereport",
        isAccessible: keys.includes("Issue Receive Report"),
      },
      {
        type: "divider",
        id: "divider-24",
      },
      {
        id: "ratematch",
        title: "Rate Match Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/ratematchreport",
        isAccessible: keys.includes("Rate Match Report"),
      },
      {
        type: "divider",
        id: "divider-25",
      },
      {
        id: "allnewreport",
        title: "All Five Reports",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Purchase and Purchase return report") ||
          keys.includes("Jobworker workstation report") ||
          keys.includes("Sales and Return List report"),
        children: [
          {
            id: "purchaseandpurchasereturn",
            title: "Purchase and Purchase Return Report",
            type: "item",
            url: "/dashboard/reports/purchaseandpurchasereturn",
            isAccessible: keys.includes("Purchase and Purchase return report"),
          },
          {
            id: "jobworkerworkstation",
            title: "Jobworker Workstation Report",
            type: "item",
            url: "/dashboard/reports/jobworkerworkstation",
            isAccessible: keys.includes("Jobworker workstation report"),
          },
          {
            id: "salesandreturnlist",
            title: "Sales and Return List Report",
            type: "item",
            url: "/dashboard/reports/salesandreturnlist",
            isAccessible: keys.includes("Sales and Return List report"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-26",
      },
      {
        id: "ordersummaryreport",
        title: "Order Summary Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/ordersummaryreport",
        isAccessible: keys.includes("Order Summary Report"),
        // isAccessible: true,
      },
      {
        type: "divider",
        id: "divider-27",
      },
      {
        id: "categorywisesalesreport",
        title: "Category Wise Sales Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/categorywisesalesreport",
        isAccessible: keys.includes("Category Wise Sales Report"),
        // isAccessible: true,
      },
      {
        type: "divider",
        id: "divider-28",
      },
      {
        id: "maincategorywisesalesreport",
        title: "Main Category Wise Sales Report",
        type: "item",
        icon: "play_circle_outline",
        url: "/dashboard/reports/maincategorywisesalesreport",
        isAccessible: keys.includes("Main Category Wise Sales Report"),
        // isAccessible: true,
      },
      {
        type: "divider",
        id: "divider-29",
      },
    ];
    return dataArr;
  }
}

export default reportNavConfig;
