// import React from 'react';
import BlankPage from './BlankPage';

export const BlankPageAppConfig = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/dashboard/masters',
            component: BlankPage
        }
    ]
};
