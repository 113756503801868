import React, { useEffect } from "react";
import NavbarSetting from "app/main/NavbarSetting/NavbarSetting"
import { useDispatch } from "react-redux";

const ReportBlankPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    NavbarSetting('Report', dispatch);
    //eslint-disable-next-line
  }, []);

  return <div></div>;
};

export default ReportBlankPage;