class mobAppAdminAppConfig {

  static mobAppAdminAppConfigArr(){

    const roleOfUser = localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : null;
    const module = roleOfUser ? roleOfUser['Mobile-app Admin'] : null
    const keys = module ? Object.keys(module) : []

    const dataArr = [
      {
        id: "Orders",
        title: "Orders",
        type: "item",
        url: "/dashboard/mobappadmin/orders",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Orders'),
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "retailerMaster",
        title: "Retailer Master",
        type: "item",
        url: "/dashboard/mobappadmin/retailermaster",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Retailer Master'),
      },
      {
        type: "divider",
        id: "divider-2",
      },
      {
        id: "userMaster",
        title: "User Master",
        type: "item",
        url: "/dashboard/mobappadmin/usermaster",
        icon: "play_circle_outline",
        isAccessible : keys.includes('User Master'),
      },
      {
        type: "divider",
        id: "divider-3",
      },    
      {
        id: "otherlead",
        title: "Other Lead",
        type: "item",
        url: "/dashboard/mobappadmin/otherlead",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Other Lead'),
      },
      {
        type: "divider",
        id: "divider-4",
      },    
      {
        id: "crm",
        title: "CRM",
        type: "item",
        url: "/dashboard/mobappadmin/crm",
        icon: "play_circle_outline",
        isAccessible : keys.includes('CRM'),
      },
      {
        type: "divider",
        id: "divider-5",
      },    
      {
        id: "Salesman",
        title: "Salesman Master",
        type: "item",
        url: "/dashboard/mobappadmin/salesman",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Salesman Master'),
      },
      {
        type: "divider",
        id: "divider-6",
      },
      {
        id: "distributorMaster",
        title: "Distributor Master",
        type: "item",
        url: "/dashboard/mobappadmin/distributormaster",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Distributor Master'),
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "categories",
        title: "Categories",
        type: "item",
        url: "/dashboard/mobappadmin/categories",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Categories'),
      },
      {
        type: "divider",
        id: "divider-8",
      },
      {
        id: "teaserDesign",
        title: "Teaser Design",
        type: "item",
        url: "/dashboard/mobappadmin/teaserdesign",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Teaser Design'),
      },
      {
        type: "divider",
        id: "divider-9",
      },
      {
        id: "branding",
        title: "Branding",
        type: "item",
        url: "/dashboard/mobappadmin/branding",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Branding'),
      },
      {
        type: "divider",
        id: "divider-10",
      },
      {
        id: "salesmanAssociation",
        title: "Salesman Association",
        type: "item",
        url: "/dashboard/mobappadmin/salesmanassociation",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Salesman Association'),
      },
      {
        type: "divider",
        id: "divider-11",
      },
      {
        id: "companyAssociation",
        title: "Company Association",
        type: "item",
        url: "/dashboard/mobappadmin/companyassociation",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Company Association'),
      },
      {
        type: "divider",
        id: "divider-12",
      },
      {
        id: "pushNotification",
        title: "Push Notification",
        type: "item",
        url: "/dashboard/mobappadmin/pushnotification",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Push Notification'),
      },
      {
        type: "divider",
        id: "divider-13",
      },
      {
        id: "distributerCatalogue",
        title: "Distributor Catalogue",
        type: "item",
        url: "/dashboard/mobappadmin/distributercatalogue",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Distributor Catalogue'),
      },
      {
        type: "divider",
        id: "divider-14",
      },
      {
        id: "myCatalouge",
        title: "My Catalogue",
        type: "item",
        url: "/dashboard/mobappadmin/mycatalogue",
        icon: "play_circle_outline",
        isAccessible : keys.includes('My Catalogue'),
      },
      {
        type: "divider",
        id: "divider-15",
      },
      {
        id: "cms",
        title: "CMS",
        type: "item",
        url: "/dashboard/mobappadmin/cms",
        icon: "play_circle_outline",
        isAccessible : keys.includes('CMS'),
      },
      {
        type: "divider",
        id: "divider-16",
      },
      {
        id: "news&Updates",
        title: "News & Updates",
        type: "item",
        url: "/dashboard/mobappadmin/newsupdate",
        icon: "play_circle_outline",
        isAccessible : keys.includes('News & Updates'),
      },
      {
        type: "divider",
        id: "divider-17",
      },
      {
        id: "exibitionMaster",
        title: "Exhibition Master",
        type: "item",
        url: "/dashboard/mobappadmin/exhibitionmaster",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Exhibition Master'),
      },
      {
        type: "divider",
        id: "divider-18",
      },
      {
        id: "ProspectiveOrder",
        title: "Prospective Order",
        type: "item",
        url: "/dashboard/mobappadmin/prospectiveorders",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Prospective Orders'),
      },
      {
        type: "divider",
        id: "divider-19",
      },
      {
        id: "LoginDetails",
        title: "Login Details",
        type: "item",
        url: "/dashboard/mobappadmin/logindetails",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Login Details'),
      },
      {
        type: "divider",
        id: "divider-20",
      },
      {
        id: "primaryAllocation",
        title: "Primary Allocation",
        type: "item",
        url: "/dashboard/mobappadmin/primaryallocation",
        icon: "play_circle_outline",
        isAccessible : keys.includes('Primary Allocation'),
      },
      {
        type: "divider",
        id: "divider-21",
      },
      {
        id: "UserComplain",
        title: "User Complain",
        type: "item",
        url: "/dashboard/mobappadmin/usercomplain",
        icon: "play_circle_outline",
        isAccessible : true
      },
      {
        type: "divider",
        id: "divider-22",
      },
      // {
      //   id: "logout",
      //   title: "Logout",
      //   type: "item",
      //   url: "/logout",
      //   icon: "play_circle_outline",
      // },
      // {
      //   type: "divider",
      //   id: "divider-15",
      // }, 
    ];
    return dataArr
  }
}

export default mobAppAdminAppConfig;
  