class navigationConfig {
  static navigationConfigArr() {
    const isSuperAdmin = localStorage.getItem("isSuperAdmin");
    const roleOfUser = localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null;
    const module = roleOfUser ? roleOfUser["Master"] : null;
    const keys = module ? Object.keys(module) : [];

    const dataArr = [
      {
        id: "itemType",
        title: "Main Item Type",
        type: "item",
        url: "/dashboard/masters/itemtype",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Main Item Type"),
      },
      {
        type: "divider",
        id: "divider-1",
      },
      {
        id: "stockGroup",
        title: "Stock Group",
        type: "item",
        url: "/dashboard/masters/stockgroup",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Stock Group"),
      },
      {
        type: "divider",
        id: "divider-2",
      },
      {
        id: "stkName",
        title: "Stock Name",
        type: "item",
        url: "/dashboard/masters/stockname",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Stock Name"),
      },
      {
        type: "divider",
        id: "divider-3",
      },
      // {
      //   id: "employee",
      //   title: "Employee",
      //   type: "item",
      //   url: "/dashboard/masters/employee",
      //   icon: "play_circle_outline",
      // },
      // {
      //   type: "divider",
      //   id: "divider-4",
      // },
      {
        id: "department",
        title: "Department",
        type: "item",
        url: "/dashboard/masters/department",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Department"),
      },
      {
        type: "divider",
        id: "divider-5",
      },
      {
        id: "process",
        title: "Process",
        type: "item",
        url: "/dashboard/masters/process",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Process"),
      },
      {
        type: "divider",
        id: "divider-6",
      },
      {
        id: "processline",
        title: "Process Line",
        type: "item",
        url: "/dashboard/masters/processline",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Process Line"),
      },
      {
        type: "divider",
        id: "divider-7",
      },
      {
        id: "clients",
        title: "Client / Party",
        type: "item",
        url: "/dashboard/masters/clients",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Client / Party"),
      },
      {
        type: "divider",
        id: "divider-8",
      },
      {
        id: "b2cclients",
        title: "B2C Client",
        type: "item",
        url: "/dashboard/masters/btocclient",
        icon: "play_circle_outline",
        isAccessible: keys.includes("B2C Client"),
      },
      {
        type: "divider",
        id: "divider-9",
      },
      {
        id: "categories",
        title: "Category (Product(S) Type)",
        type: "item",
        url: "/dashboard/masters/productcategory",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Category (Product(s) Type)"),
      },
      {
        type: "divider",
        id: "divider-10",
      },
      {
        id: "vendors",
        title: "Vendors",
        type: "item",
        url: "/dashboard/masters/vendors",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Vendors"),
      },
      {
        type: "divider",
        id: "divider-11",
      },
      {
        id: "jobWorkers",
        title: "Job Worker",
        type: "item",
        url: "/dashboard/masters/jobworker",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Job Worker"),
      },
      {
        type: "divider",
        id: "divider-12",
      },
      {
        id: "workstation",
        title: "Work Station",
        type: "item",
        url: "/dashboard/masters/workstation",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Work Station"),
      },
      {
        type: "divider",
        id: "divider-13",
      },
      {
        id: "hallmarkstation",
        title: "Hallmark Station",
        type: "item",
        url: "/dashboard/masters/hallmarkstation",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Hallmark Station"),
      },
      {
        type: "divider",
        id: "divider-14",
      },
      {
        id: "salesmen",
        title: "Salesman",
        type: "item",
        url: "/dashboard/masters/salesman",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Salesman"),
      },
      {
        type: "divider",
        id: "divider-15",
      },
      {
        id: "variant_attribute",
        title: "Variant Attribute",
        type: "item",
        url: "/dashboard/masters/variantattribute",
        icon: "play_circle_outline",
        isAccessible: keys.includes("Variant Attribute"),
      },
      {
        type: "divider",
        id: "divider-16",
      },
      {
        id: "designer",
        title: "Designer",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible: isSuperAdmin == 1 ? true : false,
        children: [
          {
            id: "designeruser",
            title: "Design User",
            type: "item",
            url: "/dashboard/masters/designuser",
            isAccessible: isSuperAdmin == 1 ? true : false,
          },
          {
            id: "designerRole",
            title: "Designer Role",
            type: "item",
            url: "/dashboard/masters/designerrole",
            isAccessible: isSuperAdmin == 1 ? true : false,
          },
          {
            id: "designerlocation",
            title: "Designer Location",
            type: "item",
            url: "/dashboard/masters/designerlocation",
            isAccessible: isSuperAdmin == 1 ? true : false,
          },
        ],
      },
      {
        type: "divider",
        id: "divider-17",
      },
      {
        id: "users",
        title: "Users",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible: isSuperAdmin == 1 ? true : false,
        children: [
          {
            id: "systemUser",
            title: "System User",
            type: "item",
            url: "/dashboard/masters/systemuser",
            isAccessible: isSuperAdmin == 1 ? true : false,
          },
          {
            id: "userRole",
            title: "User Role",
            type: "item",
            url: "/dashboard/masters/userrole",
            isAccessible: isSuperAdmin == 1 ? true : false,
          },
        ],
      },
      {
        type: "divider",
        id: "divider-18",
      },
      {
        id: "rateProfiles",
        title: "Rate Profiles",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Sales Rate profile") ||
          keys.includes("Tagging Rate profile") ||
          keys.includes("Jobworker/Vendor Rate profile") ||
          keys.includes("Work Station Rate profile"),
        children: [
          {
            id: "salesRateProfile",
            title: "Sales Rate Profile",
            type: "item",
            url: "/dashboard/masters/salesrateprofile",
            isAccessible: keys.includes("Sales Rate profile"),
          },
          {
            id: "taggingRateProfile",
            title: "Tagging Rate Profile",
            type: "item",
            url: "/dashboard/masters/taggingrateprofile",
            isAccessible: keys.includes("Tagging Rate profile"),
          },
          {
            id: "jobworkervendorrateprof",
            title: "Job Worker & Vendor Rate Profile",
            type: "item",
            url: "/dashboard/masters/jobworkervendorrateprof",
            isAccessible: keys.includes("Jobworker/Vendor Rate profile"),
          },
          {
            id: "workstationprofile",
            title: "Work Station Rate Profile",
            type: "item",
            url: "/dashboard/masters/workstationrateprof",
            isAccessible: keys.includes("Work Station Rate profile"),
          },
        ],
      },
      {
        type: "divider",
        id: "divider-19",
      },
      {
        id: "settings",
        title: "Settings",
        type: "collapse",
        icon: "play_circle_outline",
        isAccessible:
          keys.includes("Site Settings") ||
          keys.includes("Tag System") ||
          keys.includes("Tag Printer") ||
          keys.includes("HSN Master") ||
          keys.includes("Weight Tolerance") ||
          keys.includes("Finish Purity Master") ||
          keys.includes("Setting Method") ||
          keys.includes("Bagging") ||
          keys.includes("Type of Setting") ||
          keys.includes("TCS / TDS Ledger Rate") ||
          keys.includes(`Today's Gold Rate`) ||
          keys.includes("Hallmark Charges") ||
          keys.includes("Country") ||
          keys.includes("State") ||
          keys.includes("City"),
        children: [
          {
            id: "SiteSettings",
            title: "Site Settings",
            type: "item",
            url: "/dashboard/masters/SiteSettings",
            isAccessible: keys.includes("Site Settings"),
          },
          {
            id: "TagSystem",
            title: "Tag System",
            type: "item",
            url: "/dashboard/masters/TagSystem",
            isAccessible: keys.includes("Tag System"),
          },
          {
            id: "TagPrinter",
            title: "Tag Printer",
            type: "item",
            url: "/dashboard/masters/TagPrinter",
            isAccessible: keys.includes("Tag Printer"),
          },
          {
            id: "HSNMasters",
            title: "HSN Master",
            type: "item",
            url: "/dashboard/masters/HSNMaster",
            isAccessible: keys.includes("HSN Master"),
          },

          {
            id: "lotwgtvariation",
            title: "Weight Tolerance",
            type: "item",
            url: "/dashboard/masters/weighttolerance",
            isAccessible: keys.includes("Weight Tolerance"),
          },
          {
            id: "finishPurity",
            title: "Finish Purity Master",
            type: "item",
            url: "/dashboard/masters/finishpurity",
            isAccessible: keys.includes("Finish Purity Master"),
          },
          {
            id: "settingmethod",
            title: "Setting Method",
            type: "item",
            url: "/dashboard/masters/settingmethod",
            isAccessible: keys.includes("Setting Method"),
          },
          {
            id: "bagging",
            title: "Bagging",
            type: "item",
            url: "/dashboard/masters/bagging",
            isAccessible: keys.includes("Bagging"),
          },
          {
            id: "typeofsetting",
            title: "Type of Setting",
            type: "item",
            url: "/dashboard/masters/typeofsetting",
            isAccessible: keys.includes("Type of Setting"),
          },
          {
            id: "ledgerrate",
            title: "TCS / TDS Ledger Rate",
            type: "item",
            url: "/dashboard/masters/ledgerrate",
            isAccessible: keys.includes("TCS / TDS Ledger Rate"),
          },
          {
            id: "goldRate",
            title: "Today's Gold Rate",
            type: "item",
            url: "/dashboard/masters/goldrate",
            isAccessible: keys.includes(`Today's Gold Rate`),
          },
          {
            id: "hallmarkCharges",
            title: "Hallmark Charges",
            type: "item",
            url: "/dashboard/masters/hallmarkcharges",
            isAccessible: keys.includes("Hallmark Charges"),
          },
          {
            id: "country",
            title: "Country",
            type: "item",
            url: "/dashboard/masters/Country",
            isAccessible: keys.includes("Country"),
          },
          {
            id: "state",
            title: "State",
            type: "item",
            url: "/dashboard/masters/state",
            isAccessible: keys.includes("State"),
          },
          {
            id: "city",
            title: "City",
            type: "item",
            url: "/dashboard/masters/City",
            isAccessible: keys.includes("City"),
          },
          // {
          //   id: "importData",
          //   title: "Import Data",
          //   type: "item",
          //   // icon: "dashboard",
          //   url: "#",
          // }
        ],
      },
    ];
    return dataArr;
  }
}

export default navigationConfig;
