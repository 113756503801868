class hallmarkNavConfig{

  static hallmarkNavConfigArr(){

    const roleOfUser = localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : null;
    const module = roleOfUser ? roleOfUser['Hallmark'] : null
    const keys = module ? Object.keys(module) : []

    const dataArr = [
      {
          id: "reset",
          title: "Hallmark",
          type: "item",
          icon: "play_circle_outline",
          url: "/dashboard/hallmarks",
          isAccessible : true,
        },
        {
          type: "divider",
          id: "divider-1",
        },
      //   {
      //     id: "transfer",
      //     title: "Transfer",
      //     type: "item",
      //     url: "/dashboard/hallmark?transferhallmark",
      //     icon: "play_circle_outline",
      //   },
      //   {
      //     type: "divider",
      //     id: "divider-2",
      //   },
      //   {
      //     id: "acceptTransfer",
      //     title: "Accept Transfer",
      //     type: "item",
      //     url: "/dashboard/hallmark/accepthmtransfer",
      //     icon: "play_circle_outline",
      //   },
      //   {
      //     type: "divider",
      //     id: "divider-3",
      //   },
        {
          id: "issueHallmark",
          title: "Issue to Hallmark",
          type: "item",
          url: "/dashboard/hallmark/issuetohallmark",
          icon: "play_circle_outline",
          isAccessible : keys.includes('Issue to Hallmark'),
        },
        {
          type: "divider",
          id: "divider-4",
        },
        {
          id: "issueHallmarklist",
          title: "Hallmark Issued List",
          type: "item",
          url: "/dashboard/hallmark/issuetohallmarklist",
          icon: "play_circle_outline",
          isAccessible : keys.includes('Hallmark Issue List'),
        },
        {
          type: "divider",
          id: "divider-5",
        },
        {
          id: "receiveHallmark",
          title: "Receive from Hallmark",
          type: "item",
          url: "/dashboard/hallmark/recfromhallmark",
          icon: "play_circle_outline",
          isAccessible : keys.includes('Receive from Hallmark'),
        },
        {
          type: "divider",
          id: "divider-6",
        },
    ]; 
    return dataArr
  }
}

export default hallmarkNavConfig;
  