import React from 'react';

const Privacy = () =>{
    return (
<>
  <meta charSet="utf-8" />
  <meta httpEquiv="x-ua-compatible" content="ie=edge" />
  <title>Privacy Policy - JewelFlow</title>
  <meta name="description" content="" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  {/* Google Fonts
    ============================================ */}
  <link
    href="https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700"
    rel="stylesheet"
    type="text/css"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700"
    rel="stylesheet"
    type="text/css"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n        body {\n            font-family: "Open Sans", sans-serif;\n        }\n\n        .privacy-content {\n\n            margin-bottom: 20px;\n            color: #000;\n        }\n\n        .constant-padding {\n            padding: 0 114px;\n        }\n\n        .out-box {\n            display: block;\n            padding: 30px 0;\n        }\n\n        h1.heading {\n            font-size: 30px;\n            line-height: 1.4em;\n            font-weight: 500;\n            text-align: center;\n            margin-bottom: 3em !important;\n        }\n\n        .privacy h3, .terms h3 {\n            font-weight: 500;\n            font-size: 18px;\n            line-height: 1.6em;\n            padding-bottom: 10px;\n            margin-top: 40px;\n        }\n\n        .privacy-content p {\n            font-size: 16px;\n            color: #666;\n            margin-top: 0px;\n            line-height: 1.8em;\n            margin-bottom: 15px;\n            font-weight: inherit;\n        }\n\n        .privacy-content a {\n\n            color: #236cbd;\n\n        }\n\n        .out-box ol {\n            padding-left: 45px;\n        }\n\n        .out-box li {\n            color: #666;\n            line-height: 1.8em;\n            font-weight: inherit;\n        }\n\n\n        @media only screen and (max-width: 769px) {\n\n            #about_us {\n                padding: 50px 0 70px;\n            }\n\n            .constant-padding {\n                padding: 0;\n            }\n\n            .privacy-content .container.privacy {\n                width: 90%;\n                padding: 0;\n                margin: 0 auto;\n            }\n\n            .privacy-content .container.privacy .heading {\n                margin-bottom: 40px !important;\n            }\n\n            .privacy-content {\n                margin-top: 100px;\n            }\n        }\n\n    '
    }}
  />
  <div className="scroller" style={{overflow: "auto"}}>
    {/* this is for emulating position fixed of the nav */}
    <div className="scroller-inner">
      {/*Slider Area Start*/}
      <div className="privacy-content">
        <div className="container privacy">
          <div className="constant-padding">
            <div className="out-box general">
              <h1 className="heading">Privacy Policy</h1>
              <p>
                This privacy policy sets out how Vk Jewels Private Limited uses
                and protects any information that you give us when you use our
                App / Website. Vk Jewels Private Limited is committed to
                ensuring that your privacy is protected. Should we ask you to
                provide certain information by which you can be identified when
                using our App / Website, then you can be assured that it will
                only be used in accordance with this privacy statement. Vk
                Jewels Private Limited may change this policy from time to time
                by updating this page. You should check this page from time to
                time to ensure that you are happy with any changes.
              </p>
              <h3>What we collect</h3>
              <p>We may collect the following information:</p>
              <ol>
                <li>Name</li>
                <li>Contact information including email address</li>
                <li>
                  Demographic information such as postcode &amp; preferences
                </li>
                <li>
                  Other information relevant to customer surveys and/or offers
                </li>
                <li>
                  Video Calls Done using the app are recorded for QC and
                  Training purposes
                </li>
                <li>
                  Custom product requirement in form of Image / Video is
                  uploaded on our server
                </li>
              </ol>
              <h3>What we do with the information we gather</h3>
              <p>
                When you purchase something from our App / Website, as part of
                the buying and selling process, we collect the personal
                information you give us such as your name, address and email
                address.{" "}
              </p>
              <p>
                {" "}
                When you browse our App / Website, we also automatically receive
                your computer’s internet protocol (IP) address in order to
                provide us with information that helps us learn about your
                browser and operating system.
              </p>
              <p>
                Email marketing (if applicable): With your permission, we may
                send you emails about our store, new products and other updates.{" "}
              </p>
              <h3>Disclosure</h3>
              <p>
                We may disclose your personal information if we are required by
                law to do so or if you violate our Terms of Service.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*End of Slider Area*/}
    </div>
  </div>
</>
    )
}

export default Privacy

